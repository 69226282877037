import { Button, Grid, Paper, Box, Typography, Link, useMediaQuery, useTheme } from '@mui/material';
import { Cta, CtaType, HomeItem } from '../domain/HomeItem';
import EastIcon from '@mui/icons-material/East';
import { useNavigate } from 'react-router-dom';
import { useMobile } from '../../../hooks/use-mobile';
import { User } from '../../../types';
import { useIsTablet } from '../../../hooks/use-isTablet';

interface Props {
	items: HomeItem[];
	user: User;
}
const ItemList = (props: Props) => {
	const isTablet = useIsTablet();
	const navigate = useNavigate();

	const onclickCta = (cta: Cta) => () => {
		const { type, link, gtm_variable } = cta;

		if (gtm_variable && globalThis.location.hostname === 'studio.nuqlea.com') {
			(globalThis as any).dataLayer?.push({
				"event": gtm_variable,
				"value": {
					"user": {
						"user_id": props.user?.id,
						"user_email": props.user?.email,
						"type_external": props.user?.type_external,
						"id_external": props.user?.id_external,
						"name_external": props.user?.name_external,
						"role": props.user?.role,
						"environment": props.user?.enviroment
					}
				}
			})
		}

		if (type === CtaType.navigation) {
			navigate(link);
		}
		if (type === CtaType.redirection) {
			navigate(link, { replace: true });
		}
	};

	return (
		<Grid item xs={12}>
			<Box sx={{
				display: 'flex',
				justifyContent: 'space-between',
				flexDirection: { xs: 'column', sm: 'row' },
				gap: { xs: 2, sm: 4 }
			}}>
				{props.items.map((item, index) => (
					<Grid item sm={6} xs={12} key={item.title}>
						<Paper
							elevation={3}
							onClick={onclickCta(item.cta)}
							className="relative bg-white px-[1.5rem] py-[1rem] h-[215px]"
							sx={{
								':hover': {
									cursor: 'pointer',
									transform: 'translateY(-6px)',
								},
								transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
								borderRadius: 2.8,
							}}>
							<Box className="flex h-full flex-col md:flex-row justify-start md:justify-between">
								<Box className="flex flex-col justify-between flex-[3] pt-1">
									<Box className="flex flex-col w-full lg:w-[80%]">
										<Typography variant='h6' className="max-w-full md:max-w-[200px] pb-4 lg:pb-1" >
											{item.title}
										</Typography>
										<Typography variant="body2" className='opacity-[0.78] max-w-full xl:max-w-[300px]'>
											{item.description}
										</Typography>
									</Box>
									<Box>
									<Button
										endIcon={<EastIcon />}
										className="px-1 -ml-1 flex justify-start text-left hover:bg-transparent normal-case"
										size="large"
										onClick={onclickCta(item.cta)}
									>
										{item.cta.label}
									</Button>
									</Box>
								</Box>
								{isTablet && (
									<Box className="flex justify-end self-center xs:max-w-[200px] md:max-w-none flex-[2]">
										<img style={{ objectFit: 'contain', width: index === 0 ? '78%' : '100%' }} src={item.img} alt="" />
									</Box>
								)}
							</Box>
						</Paper>
					</Grid>
				))}
			</Box>
		</Grid>
	);
};

export default ItemList;
