import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useIntl } from 'react-intl';
import { InputLabel, MenuItem, Typography, styled, Alert, IconButton, TextField, FormControlLabel, Checkbox, Divider } from '@mui/material';
import FileUpload from 'react-material-file-upload';
import DatePicker from '../../../date-picker';
import { Delivery, DeliveryCompleteness, DeliveryType, RedeemRequestTypes } from '../../../../types';
import FullScreenLoading from '../../../full-screen-loading';
import client from '../../../../clients/client';
import { useDeliveryContext } from '../../../../contexts/deliveryContext';
import { useRecoilValue } from 'recoil';
import userState from '../../../../atoms/user-state';
import SideModalContainer from '../../../modals/side-modal-container';
import { useMobile } from '../../../../hooks/use-mobile';
import CloseIcon from '@mui/icons-material/Close';
import { DeliveryStatus } from '../../../../constants';
import DateFilesComments from './date-files-comments';
import DeliveryProducts from './delivery-products';

const ErrorBox = styled(Box)(({ theme }) => ({
	border: 'none',
	borderLeft: '3px solid #FF0000',
	borderRadius: '5px',
	backgroundColor: '#FF000033',
	color: '#FF0000',
	padding: '1em',
	minHeight: '3em',
	display: 'flex',
	alignItems: 'center',
}));

const SeparatedLabel = styled(InputLabel)`
	margin-top: 15px;
	margin-bottom: 5px;
`;

const DeliveredStatus = styled(Box)`
	color: #3DBE00;
	background-color: #3DBE005C;
	font-weight: 500;
	font-size: 13px;
	border-radius: 5px;
	max-width: 8rem;
	width: 100%;
	text-align: center;
	padding: 0.3rem;
`;

const PendingDeliveryStatus = styled(Box)`
	color: #556AC3;
	background-color: #556AC35C;
	font-weight: 500;
	font-size: 13px;
	border-radius: 5px;
	max-width: 8rem;
	width: 100%;
	text-align: center;
	padding: 0.3rem;
`;

const CanceledDeliveryStatus = styled(Box)`
	color: #FF0005;
	background-color: #FF00055C;
	font-weight: 500;
	font-size: 13px;
	border-radius: 5px;
	max-width: 8rem;
	width: 100%;
	text-align: center;
	padding: 0.3rem;
`;

interface Props {
	open: boolean;
	delivery: Delivery;
	deliveryType: RedeemRequestTypes.SHIPPING | RedeemRequestTypes.WITHDRAWAL;
	modalType: 'new' | 'edit' | 'view';
	onClose: (event?: object | null, reason?: string, newFile?: any) => void;
	redeemRequestProducts: any[];
	unity: string;
}

const ConstructorDeliveryModal = (props: Props) => {
	const intl = useIntl();
  	const isMobile = useMobile();
	const user = useRecoilValue(userState);
	const { open, delivery, deliveryType, modalType, onClose, redeemRequestProducts, unity } = props;
	const [error, setError] = useState<string>('');
	const [newDeliveryData, setNewDeliveryData] = useState<Delivery>(null as any);

	const getTitle = (type: string) => {
		switch (type) {
		case 'new':
			return 'mytokens.redeem_view.deliveries.seller_delivery_modal.title.new';
		case 'edit':
			return 'mytokens.redeem_view.deliveries.seller_delivery_modal.title.edit';
		case 'view':
		default:
			return 'mytokens.redeem_view.deliveries.seller_delivery_modal.title.view';
		}
	}

	useEffect(() => {
		setNewDeliveryData(delivery)
	}, [delivery]);

	return (
		<SideModalContainer
			isOpen={open}
			onClose={onClose}
			sx={{ width: '480px', px: 3 }}
			onClick={(e) => {
				if (e.target instanceof HTMLElement) {
					if(!['checkbox', 'checkbox-label', 'datepicker'].includes(e.target.id)) {
						e.preventDefault()
					}
				}
				e.stopPropagation()
			}}
			showCloseIcon={false}
			slideDirection={isMobile ? 'up' : 'left'}
			modalPosition={isMobile ? 'center' : 'right'}
			modalContentAlign={isMobile ? 'bottom' : undefined}
		>
			<DialogTitle>
				{newDeliveryData &&
					<Box className="flex justify-between items-start mt-[-14px]">
						<Box className="flex flex-col align-start justify-start items-start gap-1.5">
							<Box className="flex items-center gap-1">
								<Typography variant="h6" className="w-28">{intl.formatMessage({ id: getTitle(modalType ?? '') })}</Typography>
								{modalType !== 'new' ? (
										newDeliveryData?.status === 1 ? (
											<PendingDeliveryStatus>{intl.formatMessage({ id: DeliveryStatus[user.type_external][newDeliveryData?.status] })}</PendingDeliveryStatus>
										) : newDeliveryData?.status === 2 ? (
											<DeliveredStatus>{intl.formatMessage({ id: DeliveryStatus[user.type_external][newDeliveryData?.status] })}</DeliveredStatus>
										) : (
											<CanceledDeliveryStatus>{intl.formatMessage({ id: DeliveryStatus[user.type_external][newDeliveryData?.status] })}</CanceledDeliveryStatus>
										) 
									) : (
										null
									)
								}
							</Box>
							{
								modalType !== 'new' && newDeliveryData?.id &&
								<Box className="flex items-center gap-1">
									<Typography variant="caption" className="font-light">ID:</Typography>
									<Typography variant="caption" className="text-[.62em] font-medium">{newDeliveryData?.id}</Typography>
								</Box>
							}
						</Box>
						<IconButton aria-label="close" onClick={onClose} className={modalType !== 'new' ? 'mt-[-4px]' : 'mt-[-6px]'}>
							<CloseIcon />
						</IconButton>
					</Box>
				}
			</DialogTitle>
			<DialogContent>
				<DeliveryProducts
					unity={unity}
					delivery={delivery}
					modalType={modalType}
					setError={setError}
					redeemRequestProducts={redeemRequestProducts}
					newDeliveryData={newDeliveryData}
					setNewDeliveryData={setNewDeliveryData}
				/>
				<Box>
					<SeparatedLabel sx={{ fontWeight: 900, display: 'flex', flexDirection: 'column' }}>
						{intl.formatMessage({ id: 'mytokens.redeem_view.deliveries.seller_delivery_modal.estimated_delivery_date' })}
					</SeparatedLabel>
					<DatePicker
						identifier="estimated-delivery-date"
						disabled={modalType === 'view' || (newDeliveryData?.status === 2 && modalType === 'edit')}
						value={newDeliveryData?.estimated_delivery_date ? new Date(newDeliveryData?.estimated_delivery_date) : undefined}
						onChange={(value) => setNewDeliveryData({ ...newDeliveryData, estimated_delivery_date: value?.toISOString() ?? '' })}
					/>
				</Box>
				<DateFilesComments
					delivery={delivery}
					modalType={modalType}
					error={error}
					setError={setError}
					newDeliveryData={newDeliveryData}
					setNewDeliveryData={setNewDeliveryData}
				/>
				<Divider orientation='horizontal' flexItem sx={{ my: 2 }} />
				{Boolean(error) && <ErrorBox>{error}</ErrorBox>}
			</DialogContent>
			<DialogActions sx={{ justifyContent: 'center', gap: 1 }}>
				<Button
					disabled={Boolean(error)}
					onClick={() => onClose(null, 'cancelButtonClick')}
					sx={{
						width: '162px',						
					}}
					variant="contained"
				>
					{intl.formatMessage({ id: 'common.close' })}
				</Button>
			</DialogActions>
		</SideModalContainer>
	);
};

export default ConstructorDeliveryModal;

