import React, { useState } from 'react';
import { Box, Button, Grid, Input, InputLabel, Paper, PaperProps, Typography } from '@mui/material';
import empty from '../assets/addImage.svg';
import { useIntl } from 'react-intl';
import InformationBar from './information-bar';
import { blue } from '@mui/material/colors';

interface ImageUploaderProps extends PaperProps {
	label: string;
	description: string;
	onImageSelect: (image: File) => void;
	maxDimensions?: [number, number];
	maxSizeInMB?: number;
	currentImg?: string;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
	label,
	description,
	onImageSelect,
	maxDimensions = [10000, 10000],
	maxSizeInMB = 1000,
	currentImg,
	...props
}) => {
	const intl = useIntl();
	const [selectedImage, setSelectedImage] = useState<string | ArrayBuffer | null>(null);
	const [error, setError] = useState<string>();

	const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		setError(undefined);

		if (file) {
			const allowedFormats = ['image/jpeg', 'image/png', 'image/svg'];
			if (!allowedFormats.includes(file.type)) {
				setError(intl.formatMessage({ id: 'auth.signup.logo_image.invalid_type' }));
				return;
			}

			const image = new Image();
			image.src = URL.createObjectURL(file);
			image.onload = () => {
				if (image.width > maxDimensions[0] || image.height > maxDimensions[1]) {
					setError(
						intl.formatMessage(
							{
								id: 'auth.signup.logo_image.invalid_dimensions',
							},
							{ maxWidth: maxDimensions[0], maxHeight: maxDimensions[1] }
						)
					);
					return;
				}

				if (file.size > maxSizeInMB * 1024 * 1024) {
					setError(intl.formatMessage({ id: 'auth.signup.invalid_size' }, { maxFileSize: maxSizeInMB }));
					return;
				}

				const reader = new FileReader();

				reader.onloadend = () => {
					setSelectedImage(reader.result);
					onImageSelect(file);
				};

				reader.readAsDataURL(file);
			};
		}
	};

	return (
		<Paper {...props} elevation={0} sx={{ display: 'flex' }}>
			<Grid container spacing={5}>
				<Grid item xs={5} xl={3}>
					<InputLabel>{label}</InputLabel>
					<label htmlFor="image-input">
						<Input id="image-input" type="file" style={{ display: 'none' }} onChange={handleImageChange} />
						<Button
							color="primary"
							component="span"
							sx={{
								width: '7.5rem',
								height: '7.5rem',
								border: '1px solid #D9D9D9',
								bgcolor: '#F7F7F7',
								borderRadius: '0.3rem',
								mt: '0.3rem',
								mb: '0.6rem',
							}}
						>
							{selectedImage ? (
								<img
									src={selectedImage as string}
									alt="Selected Logo"
									style={{ width: '100%', height: '100%', objectFit: 'fill' }}
								/>
							) : (
								<img
									src={currentImg ? currentImg : empty}
									alt="Default Logo"
									style={{ width: '100%', height: '100%', objectFit: 'fill' }}
								/>
							)}
						</Button>
					</label>
				</Grid>

				<Grid item xs={7} xl={9} alignItems="center">
					<Box sx={{ display: 'flex', height: '100%', alignItems: 'center' }}>
						<Box sx={{ width: '12.5rem', pl: '1rem' }}>
							<Typography>{description}</Typography>
							<Button
								variant="contained"
								sx={{
									boxShadow: 'none',
									bgcolor: blue[50],
									color: blue.A700,
									fontWeight: 500,
									mt: '10px',
									'&:hover': {
										bgcolor: blue[50],
									},
								}}
								component="label"
								htmlFor="image-input"
							>
								{intl.formatMessage({ id: 'settings.company.general_tab.logo_upload' })}
							</Button>
						</Box>
					</Box>
				</Grid>

				{error && (
					<Grid item xs={12} sx={{ pt: '0 !important' }}>
						<InformationBar
							color="red"
							icon="error"
							message={error}
							sx={{ height: '30px', borderLeft: null, alignItems: 'start', ml: '-40px' }}
						/>
					</Grid>
				)}
			</Grid>
		</Paper>
	);
};

export default ImageUploader;
