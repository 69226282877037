import { Grid, Typography, debounce, Skeleton, Paper, Card, CardContent, Modal } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import client from '../../clients/client';
import { DollarValueInPesos } from '../../types';
import TextField from '@mui/material/TextField';
import { FormattedMessage, useIntl } from 'react-intl';
import { Column, PaginatableTable } from '../table-paginatable';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import { MoreVert, Search as SearchIcon } from '@mui/icons-material';
import { useMobile } from '../../hooks/use-mobile';
import SelectWithCheck, { Option } from '../select-with-custom-check';
import { Row } from '../../helpers/referencePricesHelper';
import * as helper from '../../helpers/referencePricesHelper';
import { formatNumberWithCurrency } from '../../utils/data-utils';
import PageContainer from '../page-container';
import DollarValueCard from './dollar-value-card';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate } from 'react-router-dom';
import BasePageFrame from '../base-components/base-page-frame';
import InfoMarker from '../info-marker';
import userState from '../../atoms/user-state';
import { useRecoilValue } from 'recoil';

interface Dollar {
	lastUpdateDate: Date;
	dollarsMap: Map<string, DollarValueInPesos>;
}

const ReferencePrices = () => {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	const isMobile: boolean = useMobile();
	const user = useRecoilValue(userState);
	const intl = useIntl();
	const columns: Column[] = !isMobile
		? [
				{ id: 'rubro', label: 'Rubro', width: '15%' },
				{ id: 'name', label: 'Nombre', width: '55%' },
				{ id: 'formattedPrice', label: 'Precio S/IVA', width: '15%' },
				{ id: 'date', label: 'Ult. actualización', width: '20%' },
		  ]
		: [
				{ id: 'name', label: 'Nombre', width: '50%' },
				{ id: 'formattedPrice', label: 'Precio S/IVA', width: '40%' },
				{ id: 'onClickOpenModal', label: '', width: '10%' },
		  ];
	const searcherPlaceholder = intl.formatMessage({ id: 'common.reference_prices.searcher_placeholder' });
	const [dollar, setDollar] = useState<Dollar>({ lastUpdateDate: new Date(), dollarsMap: new Map() });
	const [rows, setRows] = useState<Row[]>([]);
	const [filteredRows, setFilteredRows] = useState<Row[]>([]);
	const [searchText, setSearchText] = useState<string>('');
	const [dropdownOptions, setDropDownOptions] = useState<Option[]>([
		{ value: 'Todos los rubros', label: 'Todos los rubros' },
	]);
	const [dropDownSelected, setDropDownSelected] = useState<string>('Todos los rubros');
	const [selectedRowToOpenOnMobile, setSelectedRowToOpenOnMobile] = useState<null | Row>(null);

	const handleDropDownChange = (value: string) => {
		setDropDownSelected(value);
	};

	const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchText(event.target.value);
	};

	useEffect(() => {
		const fetchReferencePrices = debounce(() => {
			client
				.getReferencePricesList({ token: user.token })
				.then((data) => {
					const { dollarValues, referencePricesList, categories } = data;
					const onlyDollarCCLandOfficial = dollarValues.filter(
						(dollar: DollarValueInPesos) => dollar.casa === 'contadoconliqui' || dollar.casa === 'oficial'
					);

					const dollarsMap: Map<string, DollarValueInPesos> = new Map(
						onlyDollarCCLandOfficial.map((element: DollarValueInPesos) => [element.casa, element])
					);
					const categoriesFormatted: Option[] = ['Todos los rubros', ...categories].map((category) => ({
						value: category,
						label: category,
					}));

					onlyDollarCCLandOfficial.sort((a: DollarValueInPesos, b: DollarValueInPesos) => {
						if (a.fechaActualizacion < b.fechaActualizacion) {
							return 1;
						}
						if (a.fechaActualizacion > b.fechaActualizacion) {
							return -1;
						}
						return 0;
					});

					setDollar({
						lastUpdateDate: new Date(onlyDollarCCLandOfficial[0].fechaActualizacion),
						dollarsMap,
					});
					setRows(
						referencePricesList.map((item: Row) => {
							const row: Row = {
								sku: item.sku,
								rubro: item.rubro,
								name: item.name,
								price: item.price,
								formattedPrice: formatNumberWithCurrency(item.price, 2),
								created_at: item.created_at,
								date: item.created_at ? intl.formatDate(item.created_at, { timeZone: 'UTC' }) : '',
							};

							if (isMobile) {
								row.onClickOpenModal = <MoreVert onClick={() => setSelectedRowToOpenOnMobile(row)} />;
							}

							return row;
						})
					);
					setDropDownOptions(categoriesFormatted);
				})
				.catch(() => {
					setDollar({ lastUpdateDate: new Date(), dollarsMap: new Map() });
					setRows([]);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}, 250);

		fetchReferencePrices();
	}, []);

	useEffect(() => {
		const flagSearchText: boolean = searchText !== '';
		const flagDropDownSelected: boolean = dropDownSelected !== 'Todos los rubros';
		const newFilteredRows: Row[] = [];

		if (!flagSearchText && !flagDropDownSelected) {
			// isn't necessary to filter the rows
			setFilteredRows(rows);
		} else {
			rows.forEach((row) => {
				if (flagSearchText && flagDropDownSelected) {
					if (helper.searchTextMatchesAColumn(searchText, row) && helper.isSameCategory(dropDownSelected, row)) {
						// filter only if matches category and if the text is includes in one column
						newFilteredRows.push(row);
					}
				} else if (flagSearchText && helper.searchTextMatchesAColumn(searchText, row)) {
					// filter if the text is includes in one column
					newFilteredRows.push(row);
				} else if (flagDropDownSelected && helper.isSameCategory(dropDownSelected, row)) {
					// filter only if matches category
					newFilteredRows.push(row);
				}
			});
			setFilteredRows(newFilteredRows);
		}
	}, [searchText, dropDownSelected, rows, dropdownOptions]);
	
	return (
		<BasePageFrame transparent title={intl.formatMessage({ id: 'common.reference_prices.title' })}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<InfoMarker
						message={
							<Typography variant="body2">
								<FormattedMessage
									id="common.reference_prices.info"
									values={{
										link: (
											<span
												style={{ color: '#406AFF', textDecoration: 'underline', cursor: 'pointer' }}
												onClick={() => navigate('/new-quotation')}
											>
												{intl.formatMessage({ id: 'common.reference_prices.to_quote' })}
											</span>
										),
									}}
								/>
							</Typography>
						}
						variation="banner"
						colorScheme="info"
						icon="info"
						sx={{ justifyContent: 'left' }}
					></InfoMarker>
				</Grid>
				<Grid container spacing={2} marginTop={2} sx={{ pl: '1rem' }}>
					<Grid item xs={12} md={4}>
						<DollarValueCard
							dollarType={''}
							dollarsMap={dollar.dollarsMap}
							isFirstDollarCardWithDate={true}
							lastUpdateDate={dollar.lastUpdateDate}
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<DollarValueCard
							dollarType={'oficial'}
							dollarsMap={dollar.dollarsMap}
							isFirstDollarCardWithDate={false}
							lastUpdateDate={dollar.lastUpdateDate}
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<DollarValueCard
							dollarType={'contadoconliqui'}
							dollarsMap={dollar.dollarsMap}
							isFirstDollarCardWithDate={false}
							lastUpdateDate={dollar.lastUpdateDate}
						/>
					</Grid>
				</Grid>

				<Paper
					elevation={0}
					sx={{ padding: '2.2rem', marginTop: '2.2rem', width: '100%', borderRadius: 2, ml: '1rem' }}
				>
					<Grid container spacing={2}>
						<Grid item xs={12} lg={9}>
							<TextField
								fullWidth
								onChange={handleSearchInputChange}
								placeholder={isMobile ? 'Ingresa' : searcherPlaceholder}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon />
										</InputAdornment>
									),
								}}
							/>
						</Grid>

						<Grid item xs={12} lg={3}>
							<FormControl fullWidth>
								<SelectWithCheck
									options={dropdownOptions}
									defaultValue={dropdownOptions[0]?.value}
									onValueChangeCallback={handleDropDownChange}
								></SelectWithCheck>
							</FormControl>
						</Grid>
					</Grid>

					<Grid sx={{ mt: '2.2rem' }} item xs={12}>
						{isLoading ? (
							<Skeleton variant="rectangular" height={'50vh'} animation="wave" />
						) : (
							<>
								<PaginatableTable
									columns={columns}
									noResultsMessage={intl.formatMessage({ id: 'common.table.quotations.empty_search_message' })}
									isEmptyDataSource={rows.length === 0}
									rows={filteredRows}
									emptyDataSourceMessage={intl.formatMessage({ id: 'common.table.empty_message' })}
									rowsPerPage={filteredRows.length}
									disablePagination
								/>
								<Typography mt={'1.9rem'} variant="h6" fontWeight={500}>
									<FormattedMessage
										id="common.reference_prices.footer"
										values={{
											link: (
												<span
													style={{ color: '#406AFF', cursor: 'pointer' }}
													onClick={() => navigate('/new-quotation')}
												>
													{intl.formatMessage({ id: 'common.reference_prices.to_quote' })}
												</span>
											),
										}}
									/>
								</Typography>
							</>
						)}
					</Grid>
				</Paper>
			</Grid>
			<Modal open={Boolean(selectedRowToOpenOnMobile) && isMobile} onClose={() => setSelectedRowToOpenOnMobile(null)}>
				<Card elevation={4} sx={{ position: 'absolute', top: '45%', left: '5vw', maxWidth: '90vw' }}>
					<CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
						<Typography>
							<b>{intl.formatMessage({ id: 'common.reference_prices.category' })}:</b>
							{` ${selectedRowToOpenOnMobile?.rubro}`}
						</Typography>
						<Typography>
							<b>{intl.formatMessage({ id: 'common.reference_prices.name' })}:</b>
							{` ${selectedRowToOpenOnMobile?.name}`}
						</Typography>
						<Typography>
							<b>{intl.formatMessage({ id: 'common.reference_prices.price' })}:</b>
							{` ${selectedRowToOpenOnMobile?.formattedPrice}`}
						</Typography>
						<Typography>
							<b>{intl.formatMessage({ id: 'common.reference_prices.last_update' })}:</b>
							{` ${selectedRowToOpenOnMobile?.date}`}
						</Typography>
					</CardContent>
				</Card>
			</Modal>
		</BasePageFrame>
	);
};

export default ReferencePrices;
