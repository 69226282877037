import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Business } from '@mui/icons-material';
import { Badge, Button, createTheme } from '@mui/material';
import ContactMenuItem from './contact-menu-Item';
import { ThemeProvider } from '@emotion/react';
import { useIntl } from 'react-intl';
import { User, Role } from '../types';
import { useNavigate } from 'react-router-dom';

import { useRecoilValue } from 'recoil';
import organizationState from '../atoms/organization-state';

interface Props {
	user: User;
	organizationInfo?: string;
	menuOptions: UserMenuOptions[];
	isMobile: boolean;
	showNotificationBadge?: boolean;
	mobileNotifCallback?: () => void;
}

export interface UserMenuOptions {
	description: string;
	onclick?: (param?: any) => void;
	notificationState?: boolean;
	isDivider?: boolean;
}

const AvatarUserMenu = (props: Props) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const organizationData = useRecoilValue(organizationState);
	const navigate = useNavigate();

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const theme = createTheme({
		components: {
			MuiMenuItem: {
				styleOverrides: {
					root: {
						paddingLeft: '25px',
						fontFamily: 'inherit',
						fontSize: '14px',
						fontWeight: 400,
					},
				},
			},
		},
	});
	const intl = useIntl();

	return (
		<React.Fragment>
			<Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
				<Tooltip title="">
					<Button
						onClick={handleClick}
						aria-controls={open ? 'account-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
						className="rounded-3xl bg-white h-9"
					>
						<Avatar
							src={organizationData?.image_url || props.user.image || undefined}
							className="w-6 h-6 rounded-[3px] bg-[#D9D9D9] m-[0_5px_0_10px]"
						>
							{organizationData?.image_url || props.user.image ? null : <Business sx={{ color: '#2F2F3C' }} />}
						</Avatar>
						<Divider orientation="vertical" className="text-[#ADADB0] h-6 m-2" />
						<Avatar src={props.user.profile_image} className="bg-[#D9D9D9] w-6 h-6 text-black ml-1">
							{props.user.profile_image ? null : props.user.name.charAt(0).toUpperCase()}
						</Avatar>
						<KeyboardArrowDownIcon
							className="w-6 h-6 text-text-primary m-2.5 mr-0"
							sx={{
								transform: `rotate(${open ? '180deg' : '0deg'})`,
								transition: 'transform 0.5s ease',
							}}
						/>
					</Button>
				</Tooltip>
			</Box>
			<Menu
				anchorEl={anchorEl}
				id="account-menu"
				open={open}
				onClose={handleClose}
				slotProps={{
					paper: {
						elevation: 0,
						sx: {
							overflow: 'visible',
							filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
							mt: 1,
							maxWidth: '340px',
							position: 'sticky',
							'& .MuiAvatar-root': {
								width: 32,
								height: 32,
								ml: -0.5,
								mr: 1,
							},
						},
					},
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				<ContactMenuItem
					key={Math.random() * 123456}
					sx={{
						'&:hover': {
							cursor: 'default',
							backgroundColor: 'transparent',
						},
					}}
					name={props.user.name}
					avatarImg={props.user.profile_image}
					title={intl.formatMessage({ id: 'common.my_account' }).toUpperCase()}
					info={props.user.email}
				></ContactMenuItem>

				{/* {props.isMobile && ( */}
				<ThemeProvider theme={theme}>
					{props.user.type_external !== Role.SELLER && (
						<MenuItem
							key={Math.random() * 123456}
							onClick={() => {
								props.mobileNotifCallback && props.mobileNotifCallback();
								handleClose();
							}}
						>
							{intl.formatMessage({ id: 'common.notifications' })}
							{props.showNotificationBadge && <Badge sx={{ ml: '10px' }} variant="dot" color="error"></Badge>}
						</MenuItem>
					)}
					{props.user.type_external === Role.CONSTRUCTOR && (
						<MenuItem onClick={() => navigate('my-account')}>
							{intl.formatMessage({ id: 'common.my_account' })}
						</MenuItem>
					)}
				</ThemeProvider>
				{/* )} */}

				<Divider key={Math.random() * 123456} sx={{ borderColor: '#0000001f' }} />

				<ContactMenuItem
					key={Math.random() * 123456}
					sx={{
						marginBottom: '10px',
						'&:hover': {
							cursor: 'default',
							backgroundColor: 'transparent',
						},
					}}
					name={props.user.name_external}
					info={props.organizationInfo}
					title={intl.formatMessage({ id: 'common.my_organization' }).toUpperCase()}
					avatarImg={organizationData?.image_url || props.user.image || undefined}
					avatarShape="rounded"
				></ContactMenuItem>

				<ThemeProvider theme={theme}>
					{props.menuOptions.map((opt) => {
						return opt.isDivider ? (
							<Divider key={Math.random() * 123456}></Divider>
						) : (
							<MenuItem
								key={Math.random() * 123456}
								onClick={() => {
									opt.onclick && opt.onclick();
									opt.onclick && handleClose();
								}}
							>
								{opt.description}
								{opt.notificationState && <Badge sx={{ ml: '10px' }} variant="dot" color="error"></Badge>}
							</MenuItem>
						);
					})}
				</ThemeProvider>
			</Menu>
		</React.Fragment>
	);
};

export default AvatarUserMenu;
