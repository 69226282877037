import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useIntl } from 'react-intl';
import hideDraweMenuState from '../../atoms/hide-drawer-menu-state';
import userState from '../../atoms/user-state';
import FullScreenLoading from '../full-screen-loading';
import { Button } from '@mui/material';
import { KeyboardBackspace } from '@mui/icons-material';
import client from '../../clients/client';

const WrapperProposalBackoffice = () => {
	const { listId, orderId } = useParams();
	const navigate = useNavigate();
	const intl = useIntl();

	const user = useRecoilValue(userState);
	const setHideDrawerMenu = useSetRecoilState(hideDraweMenuState);

	const [loading, setLoading] = useState(true);
	const [proposalId, setProposalId] = useState<string | null>(null);
	const iframeRef = useRef<HTMLIFrameElement>(null);

	useEffect(() => {
		const verifiedIfListIdIsMappingNewQuoteIds = async () => {
			await client
				.geProposalIdFromListId({ token: user.token, listId: listId as string })
				.then((response) => {          
          const { proposal_id } = response;
          
          if (!proposal_id) {
            // if there is no quote_id matching the list_id on the table cotizaciones_quote_mapping, then navigate back to the list
            if (orderId) {
              navigate(`/list/${listId}/${orderId}`);
            } else {
              navigate(`/list/${listId}`);
            }
          } else {
						setProposalId(proposal_id);
					}
        })
				.catch((error) => {
					console.error(error);
					if (orderId) {
						navigate(`/list/${listId}/${orderId}`);
					} else {
						navigate(`/list/${listId}`);
					}
				});
		};

		if (listId) {
			verifiedIfListIdIsMappingNewQuoteIds();
		}
	}, [listId, navigate, orderId, user.token]);

	useEffect(() => {
		setHideDrawerMenu(true);
		return () => {
			setHideDrawerMenu(false);
		};
	}, [setHideDrawerMenu]);

	const token = user.token;
  const encodedParam = btoa(JSON.stringify({ token, proposalId }));

	return (
		<div className='w-full h-fit flex justify-end items-end relative pt-[64px]'>
			{loading && <FullScreenLoading />}
			<div className='fixed top-16 left-20'>
				<Button
					onClick={() => (orderId ? navigate(`/list/${listId}/${orderId}`) : navigate(`/list/${listId}`))}
					startIcon={<KeyboardBackspace />}
				>
					{intl.formatMessage({ id: 'common.back' })}
				</Button>
			</div>
			<iframe
				ref={iframeRef}
				style={{ width: '100%', height: '90dvh', border: 'none' }}
				onLoad={() => setLoading(false)}
				src={`${process.env.REACT_APP_BACKOFFICE_BASE_URL}/shared/proposals/${encodedParam}`}
				title='proposal'
			/>
		</div>
	);
};

export default WrapperProposalBackoffice;
