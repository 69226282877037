import { Box, BoxProps, Link, Stack, Typography } from "@mui/material";
import React, { ReactNode } from "react";

interface NuqleaAlertProps extends BoxProps {
    children: ReactNode;
    backgroundColor?: string;
    childrenColor?: string;
    action?: () => void;
    actionTitle?: string;
}

const NuqleaAlert: React.FC<NuqleaAlertProps> = ({
    children,
    color = '#FF9C40',
    backgroundColor = '#FFF6EC',
    minHeight = '45px',
    alignItems = 'center',
    childrenColor = 'inherit',
    action,
    actionTitle,
    ...rest
}) => {

    return (
        <Box sx={{
            minHeight: minHeight,
            display: 'inline-flex',
            alignItems: alignItems,
            backgroundColor: backgroundColor,
            color: color,
            borderRadius: '5px',
            position: 'relative',
            paddingX: '15px',
            paddingY: '2px',
            "::before": {
                content: '""',
                position: 'absolute',
                bottom: '0px',
                left: '0',
                width: '5px',
                height: '100%',
                minHeight: minHeight,
                backgroundColor: color,
                borderTopLeftRadius: '5px',
                borderBottomLeftRadius: '5px'
            },
            ...rest.sx
        }}
            {...rest}
        >
            <Typography variant="body2" sx={{ color: childrenColor, flexGrow: 1 }} className='font-semibold'>
                {children}
                {action &&
                    <Link color={childrenColor} component="a" sx={{ cursor: 'pointer' }} onClick={action}>{actionTitle}</Link>
                }
            </Typography>
        </Box>
    );
}

export default NuqleaAlert;