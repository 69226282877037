import {
	Grid,
	Typography,
	Button,
	Box,
	Paper,
	Stack,
	Divider,
	Modal,
	Fade,
	Skeleton,
	LinearProgress,
} from '@mui/material';
import { Circle } from '@mui/icons-material';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { useMobile } from '../../../hooks/use-mobile';
import { useIntl } from 'react-intl';
import { IProjectDetailData } from '../projectTypes';
import GenericConfirmationModal from '../../modals/generic-confirmation-modal';
import { useState } from 'react';
import projectsService from '../projectsService';
import projectFilesService from '../projectFilesService';
import { BaseFile, StackedData, User } from '../../../types';
import FileFormPlaceholder from '../../file-form-placeholder';
import addressHelper from '../../../helpers/addressHelper';
import InformationBar from '../../information-bar';
import { blue } from '@mui/material/colors';
import GenericTabFrame from '../../base-components/generic-tab-frame';
import EditButton from '../../nuqlea-components/edit-button';
import StackDataDisplay from '../../base-components/stack-data-display';

interface Props {
	user: User;
	project: IProjectDetailData;
	projectFiles: BaseFile[] | null;
	isLoadingFiles: boolean;
	handleEdit: (isEditing: boolean) => void;
	handleCancel: () => void;
	callback?: () => void;
}

const ProjectSummary = (props: Props) => {
	const isMobile: boolean = useMobile();
	const intl = useIntl();
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [isBusy, setIsBusy] = useState(false);
	const [showError, setShowError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const data: StackedData[] = [
		{
			title: 'Estado',
			description: <Box className="flex items-center gap-3"><Circle className={"w-2.5" + (props.project.status === 2 ? " text-[#ffd600]" : props.project.status === 1 ? " text-[#43a047]" : " text-[#dd2c00]")} /> {props.project.status_label}</Box>
		},
		{
			title: intl.formatMessage({ id: 'projects.general.data.construction_responsible' }),
			description: props.project.construction_responsible,
		},
		{
			title: intl.formatMessage({ id: 'projects.general.data.purchasing_manager' }),
			description: props.project.purchase_responsible,
		},
		{
			title: intl.formatMessage({ id: 'projects.general.data.address' }),
			description: props.project.full_address
				? addressHelper.formatAddress({
						address: props.project.full_address.address,
						city: props.project.full_address.city,
						province: props.project.full_address.province,
				  })
				: '',
		},
		{
			title: intl.formatMessage({ id: 'projects.general.data.project_type' }),
			description: props.project.type,
		},
		{
			title: intl.formatMessage({ id: 'projects.general.data.financing_type' }),
			description: props.project.financing_type,
		},
		{
			title: intl.formatMessage({ id: 'projects.general.data.description' }),
			description: props.project.description,
		},
	];

	const onDeleteModal = () => {
		handleDelete();
		setShowDeleteModal(false);
	};

	const onCancelModal = () => {
		setShowDeleteModal(false);
	};

	const handleDelete = async () => {
		if (props.project.id) {
			try {
				setIsBusy(true);

				await projectsService.deleteProject(props.user, props.project.id);
				if (props.callback) {
					props.callback();
				}
			} catch (error) {
				setErrorMessage(intl.formatMessage({ id: 'common.error_message' }));
				console.error(error);
				setShowError(true);
			} finally {
				setIsBusy(false);
			}
		}
	};

	const handleCancel = () => {
		props.handleCancel();
	};

	return (
		<>
			<GenericTabFrame
				title={props.project.name ?? ''}
				buttons={[
					<EditButton onClick={() => props.handleEdit(true)}></EditButton>,
					<Button onClick={() => setShowDeleteModal(true)}>
						{intl.formatMessage({ id: 'projects.general.delete_button' })}
					</Button>,
				]}
			>
				<StackDataDisplay
					title={intl.formatMessage({ id: 'projects.general.data.title' })}
					data={data}
				></StackDataDisplay>

				<Typography className="pr-20 mt-10 font-extrabold">{intl.formatMessage({ id: 'common.docs' })}</Typography>

				<Divider className="my-4" orientation="horizontal"></Divider>

				{props.isLoadingFiles ? (
					<Skeleton variant="rectangular" height={38} width={166} />
				) : (
					props.projectFiles && (
						<Grid container spacing={1}>
							{props.projectFiles.map((item: BaseFile) => {
								return (
									<Grid item xs={6} key={item.id}>
										<FileFormPlaceholder
											user={props.user}
											file={item}
											previewCallback={() => projectFilesService.downloadMetadataFile(item.path ?? '', props.user)}
											downloadCallback={() => projectFilesService.downloadBinaryFile(item.path ?? '', props.user)}
										></FileFormPlaceholder>
									</Grid>
								);
							})}
						</Grid>
					)
				)}

				{(isBusy || showError) && (
					<Grid container>
						<Grid item xs={12}>
							{isBusy && <LinearProgress sx={{ mt: '30px' }}></LinearProgress>}
							{showError && (
								<InformationBar sx={{ mb: 2 }} icon="error" message={errorMessage} color="#FF3D00"></InformationBar>
							)}
						</Grid>
					</Grid>
				)}
				<Modal open={showDeleteModal} onKeyDown={(event) => event.key === 'Escape' && setShowDeleteModal(false)}>
					<Fade in={showDeleteModal}>
						<Box>
							<GenericConfirmationModal
								title={intl.formatMessage({ id: 'projects.general.delete_modal.title' })}
								message={intl.formatMessage({ id: 'projects.general.delete_modal.message' })}
								primaryButtonText={intl.formatMessage({
									id: 'common.delete',
								})}
								primaryButtonCallback={onDeleteModal}
								secondaryButtonText={intl.formatMessage({
									id: 'common.cancel',
								})}
								secondaryButtonCallback={onCancelModal}
							></GenericConfirmationModal>
						</Box>
					</Fade>
				</Modal>
			</GenericTabFrame>
		</>
	);
};

export default ProjectSummary;
