import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useIntl } from 'react-intl';
import { Alert, Typography, styled } from '@mui/material';
import { Action, User } from '../../../types';
import client from '../../../clients/client';
import FullScreenLoading from '../../full-screen-loading';
import LoadingButton from '@mui/lab/LoadingButton';
import { downloadFile } from '../../../helpers/filesHelper';

const StyledAlert = styled(Alert)(({ theme }) => ({
	border: 'none',
	backgroundColor: '#D8DCED',
	borderRadius: '10px',
	color: '#222',
}));

interface Props {
	open: boolean;
	winnerQuotations?: string[];
	quoteVersion?: number;
	listId: string;
	orderId: string;
	quotationDriveFileId: string;
	gcsFilePath?: string;
	user: User;
	onClose: (event?: object | null, reason?: string) => void;
}

const ConfirmationDialog = (props: Props) => {
	const intl = useIntl();
	const { open, winnerQuotations, quoteVersion, quotationDriveFileId, gcsFilePath, listId, orderId, user, onClose } =
		props;

	const [loading, setLoading] = useState<boolean>();
	const [error, setError] = useState<string>();
	const [downloading, setDownloading] = useState(false);

	const handleSubmit = () => {
		setLoading(true);
		setError(undefined);

		client
			.executeAction({
				listId,
				orderId,
				action: Action.FP,
				user,
				winner_quotations: winnerQuotations,
			})
			.then((response: any) => {
				onClose(null, 'success');
				window.location.reload();
			})
			.catch((error) => setError(error.message))
			.finally(() => setLoading(false));
	};

	const EstimateDownloadButton = () => {
		return (
			<>
				{quotationDriveFileId && (
					<LoadingButton
						variant="text"
						loading={downloading}
						sx={{
							py: 0,
							px: 0.25,
							width: '100%',
							maxWidth: '150px',
							justifyContent: downloading ? 'center' : 'start',
							color: '#1737B7',
							textDecoration: 'underline',
							fontWeight: 800,
						}}
						onClick={() => {
							setDownloading(true);
							if (quotationDriveFileId) {
								Promise.all([
									client.getDriveFileName({ driveFileId: quotationDriveFileId, user }),
									client.getDriveFile({
										driveFileId: quotationDriveFileId,
										fileId: 'Cotización Nuqlea',
										listId,
										user,
									}),
								])
									.then(([nameData, fileData]) => {
										downloadFile(
											fileData,
											'Cotización Nuqlea',
											props.listId ?? '1',
											nameData.filename.split('.').pop()
										);
									})
									.finally(() => setDownloading(false));
							}

							if (gcsFilePath) {
								Promise.all([
									client.downloadGCSFile(gcsFilePath, 'base64', user.id_external, user.token),
									client.downloadGCSBinaryFile(gcsFilePath, user.id_external, user.token),
								])
									.then(([nameData, fileData]) => {
										downloadFile(
											fileData,
											'Cotización Nuqlea',
											props.listId ?? '1',
											nameData.filename.split('.').pop()
										);
									})
									.finally(() => setDownloading(false));
							}
						}}
					>
						{downloading ? 'Procesando...' : `Cotización - Versión ${quoteVersion}`}
					</LoadingButton>
				)}
				{!quotationDriveFileId && `(${intl.formatMessage({ id: 'common.not_available' })})`}
			</>
		);
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			sx={{
				'& .MuiPaper-root': {
					maxWidth: '600px',
					p: 3,
					borderRadius: 4,
				},
			}}
		>
			{loading && <FullScreenLoading />}
			<DialogTitle>
				<Typography variant="h5" textAlign="center">
					{intl.formatMessage({ id: 'list.details.confirmation_dialog.title' })}
				</Typography>
			</DialogTitle>
			<DialogContent>
				<StyledAlert
					severity="info"
					sx={{ p: 1, mb: 3, '& .MuiAlert-icon': { color: '#222', fontSize: '1.4em', marginRight: '5px' } }}
				>
					{intl.formatMessage(
						{ id: 'list.details.confirmation_dialog.reminder.last' },
						{
							quotationLink: <EstimateDownloadButton />,
						}
					)}
				</StyledAlert>

				{error && (
					<Alert severity="error" sx={{ mt: 2 }}>
						{error ?? 'Ocurrió un error.'}
					</Alert>
				)}
			</DialogContent>
			<DialogActions sx={{ justifyContent: 'center', gap: 1, mb: 1 }}>
				<Button
					onClick={() => onClose(null, 'cancelButtonClick')}
					variant="contained"
					sx={{ border: '1px solid #000', borderRadius: 10, width: '120px' }}
				>
					{intl.formatMessage({ id: 'common.cancel' })}
				</Button>
				<Button
					onClick={handleSubmit}
					sx={{ background: '#000', color: '#FFF', border: '1px solid #000', borderRadius: 10, width: '120px' }}
					variant="contained"
				>
					{intl.formatMessage({ id: 'common.confirm' })}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmationDialog;
