import React, { useEffect, useReducer, Dispatch } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
	debounce,
	styled,
	Button,
	Alert,
	Box,
	Card,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { AgreementProduct } from '../../../types';
import { Outlet, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import client from '../../../clients/client';
import { useMobile } from '../../../hooks/use-mobile';
import reducer, { AgreementCondition, initialState, State } from './reducer';
import FullScreenLoading from '../../full-screen-loading';
import {
	INITIALIZE_STATE_WITH_AGREEMENT_DATA,
	RESET_ERROR_ON_FETCH,
	SET_ERROR_FROM_FETCH_TRUE,
	SET_NO_TOKENS_AVAILABLE_TO_BUY,
	SET_COMPANIES,
	SET_SELLER,
	SET_SELECTED_AGREEMENT_ID,
	TOGGLE_IS_HELP_DIALOG_OPEN,
} from './reducer/actionNames';
import userState from '../../../atoms/user-state';
import disableRootElementScrollState from '../../../atoms/disable-root-element-scroll-state';
import agreementsOptionsState from '../../../atoms/agreements-options-state';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { truncToDecimals } from '../../../utils/data-utils';
import BasePageFrame from '../../base-components/base-page-frame';
import InfoButton from '../../info-button';
import CrispCTAButton from '../../crisp-cta-button';
import { AgreementContent } from '../dashboard/tokens';

const ErrorAlert = styled(Alert)`
	position: relative;
	top: 0;
	z-index: 1001;
	width: calc(100% + 22px);
	left: -11px;
`;

export const useContextBuyTokens = () =>
	useOutletContext<
		[
			state: State,
			dispatch: Dispatch<{ type: string; payload?: any }>,
			fetch: () => void,
			totalsToRender: {
				firstSubtotal: number;
				discount: number;
				deliveryPrice: number;
				interest: number;
				finalTotal: number;
				timeValidity: string;
			}
		]
	>();

const BuyTokens = () => {
	const user = useRecoilValue(userState);
	const setDisableRootElementScroll = useSetRecoilState(disableRootElementScrollState);
	const { agreementsOptions, isLoading: isLoadingAgreementsOptions } = useRecoilValue<{
		agreementsOptions: AgreementContent[];
		isLoading: boolean;
	}>(agreementsOptionsState);

	const intl = useIntl();
	const isMobile = useMobile();
	const location = useLocation();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const [state, dispatch] = useReducer(reducer, initialState);

	const {
		agreement,
		benefit,
		companies,
		errorOnFetch,
		isHelpDialogOpen,
		isLoadingResponse,
		pricing,
		quantity,
		selectedAgreementId,
		selectedDelivery,
		selectedFinancingMethod,
	}: State = state;

	const fetchAgreementData = debounce(() => {
		client
			.getAgreement({ token: user.token, agreementId: selectedAgreementId })
			.then((response) => {
				if (response?.data?.error) {
					dispatch({ type: SET_ERROR_FROM_FETCH_TRUE });
					return;
				}

				if (response.emptyCollections) {
					dispatch({ type: SET_NO_TOKENS_AVAILABLE_TO_BUY, payload: true });
				} else {
					const financing: AgreementCondition[] = [];
					const shippings: AgreementCondition[] = [];
					let withdrawal: AgreementCondition | null = null;
					let benefit: AgreementCondition | null = null;
					response.agreement_conditions.forEach((condition: AgreementCondition) => {
						if (condition.status === 'active') {
							if (condition.condition_type_id === '4') {
								financing.push(condition);
							}
							if (condition.condition_type_id === '1') {
								shippings.push(condition);
							}
							if (condition.condition_type_id === '6') {
								withdrawal = condition;
							}
							if (condition.condition_type_id === '2' || condition.condition_type_id === '3') {
								benefit = condition;
							}
						}
					});
					//sort shippins by zones
					shippings.forEach((shipping: AgreementCondition) => {
						shipping.zones?.sort((a, b) => a.zone_label.localeCompare(b.zone_label));
					});
					shippings.sort((a, b) => {
						const zoneLabelA = a.zones?.[0]?.zone_label || '';
						const zoneLabelB = b.zones?.[0]?.zone_label || '';
						return zoneLabelA.localeCompare(zoneLabelB);
					});
					dispatch({
						type: INITIALIZE_STATE_WITH_AGREEMENT_DATA,
						payload: {
							agreement: response.agreement,
							benefit,
							collection: response.agreement_collection,
							equivalenceProductsMap: new Map(
								response.agreement_products.map((product: AgreementProduct) => [
									product.id,
									{ requireUnits: null, necessaryTokens: null },
								])
							),
							financing,
							products: response.agreement_products,
							pricing: response.agreement_pricing,
							shippings: shippings,
							selectedFinancingMethod: financing.sort((a: any, b: any) => {
								if (
									(a.name < b.name && a.name.length < b.name.length) ||
									(a.name < b.name && a.name.length === b.name.length)
								) {
									return -1;
								}
								if (
									(a.name > b.name && a.name.length > b.name.length) ||
									(a.name > b.name && a.name.length === b.name.length)
								) {
									return 1;
								}
								return 0;
							})[0],
							selectedDelivery: shippings[0] ?? withdrawal,
							withdrawal,
						},
					});
					navigate(`/my-tokens/buy/quantity-select?agreement=${selectedAgreementId}`);
				}
			})
			.catch((err) => {
				dispatch({ type: SET_ERROR_FROM_FETCH_TRUE });
			});
	}, 250);

	const fetchSeller = debounce(() => {
		client
			.getSellerId({ token: user.token, agreementId: selectedAgreementId })
			.then((data) => {
				if (Boolean(data.data?.seller)) {
					client
						.getSellerWithoutDocuments({ sellerId: `${data.data.seller}`, token: user.token })
						.then((response) => {
							dispatch({ type: SET_SELLER, payload: response });
						})
						.catch((err) => {
							dispatch({ type: SET_ERROR_FROM_FETCH_TRUE });
						});
				} else {
					dispatch({ type: SET_ERROR_FROM_FETCH_TRUE });
				}
			})
			.catch((err) => {
				dispatch({ type: SET_ERROR_FROM_FETCH_TRUE });
			});
	}, 250);

	const fetch = () => {
		dispatch({ type: RESET_ERROR_ON_FETCH });
		fetchSeller();
		fetchAgreementData();
	};

	useEffect(() => {
		if (selectedAgreementId) {
			fetch();
		}
	}, [selectedAgreementId]);

	useEffect(() => {
		if (!companies.length) {
			client
				.getConstructorBusinessNames({ token: user.token })
				.then((response) => {
					dispatch({ type: SET_COMPANIES, payload: response.businessNames });
				})
				.catch((err) => {
					dispatch({ type: SET_ERROR_FROM_FETCH_TRUE });
				});
		}
	}, [companies]);

	const getTimeValidity = ({ monthsValidity, yearsValidity }: { monthsValidity: number; yearsValidity: number }) => {
		const months =
			monthsValidity > 0
				? monthsValidity === 1
					? intl.formatMessage({ id: 'buy_tokens.collection_duration.one_month' })
					: intl.formatMessage({ id: 'buy_tokens.collection_duration.months' }, { n: monthsValidity })
				: '';
		const years =
			yearsValidity > 0
				? yearsValidity === 1
					? intl.formatMessage({ id: 'buy_tokens.collection_duration.one_year' })
					: intl.formatMessage({ id: 'buy_tokens.collection_duration.years' }, { n: yearsValidity })
				: '';

		if (years && months) {
			return intl.formatMessage(
				{ id: 'buy_tokens.collection_duration' },
				{ time: `${years} ${intl.formatMessage({ id: 'common.and' })} ${months}` }
			);
		} else if (years) {
			return intl.formatMessage({ id: 'buy_tokens.collection_duration' }, { time: `${years}` });
		} else {
			return intl.formatMessage({ id: 'buy_tokens.collection_duration' }, { time: `${months}` });
		}
	};

	let firstSubtotal = 0;
	let subtotalWithDiscount = 0;
	let discount = 0;
	let deliveryPrice = 0;
	let interest = 0;
	let monthsValidity = 0;
	let yearsValidity = 0;
	if (!isLoadingResponse && pricing && agreement) {
		firstSubtotal = truncToDecimals(quantity * (pricing?.price || 0));
		if (benefit) {
			if (
				(!benefit.min_value && !benefit.min_tokens) || // no conditions to apply discount (discount applies to all products
				(benefit.min_value && benefit.min_value <= firstSubtotal) ||
				(benefit.min_tokens && benefit.min_tokens <= quantity)
			) {
				if (benefit.value_type === 'percentage') {
					discount = truncToDecimals(firstSubtotal * ((benefit.value || 0) / 100));
				} else if (benefit.value_type === 'fixed') {
					discount = benefit.value || 0;
				}
			}
		}
		subtotalWithDiscount = firstSubtotal - discount;
		if (selectedDelivery) {
			if (selectedDelivery.value_type === 'percentage') {
				deliveryPrice = truncToDecimals(subtotalWithDiscount * ((selectedDelivery.value ?? 0) / 100));
			} else if (selectedDelivery.value_type === 'fixed') {
				deliveryPrice = selectedDelivery.value || 0;
			}
		}
		if (selectedFinancingMethod) {
			if (selectedFinancingMethod.value_type === 'percentage') {
				interest = truncToDecimals(subtotalWithDiscount * ((selectedFinancingMethod.value ?? 0) / 100));
			} else if (selectedFinancingMethod.value_type === 'fixed') {
				interest = selectedFinancingMethod.value || 0;
			}
		}

		yearsValidity = Math.floor((agreement?.token_validity ?? 0) / 12);
		monthsValidity = (agreement?.token_validity ?? 0) % 12;
	}

	const finalTotal = firstSubtotal + interest + deliveryPrice - discount;
	const timeValidity = getTimeValidity({ monthsValidity, yearsValidity });
	const totalsToRender = { firstSubtotal, discount, deliveryPrice, interest, finalTotal, timeValidity };

	const renderTitle = () => {
		const lastPathnameChunkLength = location.pathname.split('/')?.length;
		const lastPathnameChunk = location.pathname.split('/')[lastPathnameChunkLength - 1];

		switch (lastPathnameChunk) {
			case 'buy':
			case 'quantity-select':
				return intl.formatMessage({ id: 'mytokens.constructor.buy.quantity_selection.title' });

			case 'confirmation-step':
				return intl.formatMessage({ id: 'mytokens.constructor.buy.confirmation.title' });

			case 'confirmed-step':
				return intl.formatMessage({ id: 'mytokens.constructor.buy.confirmed.title' });

			default:
				return intl.formatMessage({ id: 'mytokens.constructor.buy.quantity_selection.title' });
		}
	};

	const renderSubtitle = () => {
		const lastPathnameChunkLength = location.pathname.split('/')?.length;
		const lastPathnameChunk = location.pathname.split('/')[lastPathnameChunkLength - 1];

		switch (lastPathnameChunk) {
			case 'buy':
			case 'quantity-select':
				return intl.formatMessage({ id: 'mytokens.constructor.buy.quantity_selection.subtitle' });

			case 'confirmation-step':
				return intl.formatMessage({ id: 'mytokens.constructor.buy.confirmation.subtitle' });

			case 'confirmed-step':
				return intl.formatMessage({ id: 'mytokens.constructor.buy.confirmed.subtitle' });

			default:
				return intl.formatMessage({ id: 'mytokens.constructor.buy.quantity_selection.subtitle' });
		}
	};

	const renderBackButtonTitle = () => {
		const lastPathnameChunkLength = location.pathname.split('/')?.length;
		const lastPathnameChunk = location.pathname.split('/')[lastPathnameChunkLength - 1];

		switch (lastPathnameChunk) {
			case 'buy':
			case 'quantity-select':
			case 'confirmed-step':
				return intl.formatMessage({ id: 'mytokens.constructor.buy.quantity_selection.back_button' });

			case 'confirmation-step':
				return intl.formatMessage({ id: 'mytokens.constructor.buy.confirmation.back_button' });

			default:
				return undefined;
		}
	};

	const handleGoBack = () => {
		const lastPathnameChunkLength = location.pathname.split('/')?.length;
		const lastPathnameChunk = location.pathname.split('/')[lastPathnameChunkLength - 1];

		switch (lastPathnameChunk) {
			case 'buy':
			case 'quantity-select':
			case 'confirmed-step':
				navigate(`/my-tokens?agreement=${selectedAgreementId}`);
				break;

			case 'confirmation-step':
				navigate(`/my-tokens/buy/quantity-select?agreement=${selectedAgreementId}`);
				break;

			default:
				navigate(`/my-tokens?agreement=${selectedAgreementId}`);
				break;
		}
	};

	const isOnlyOnSelectAgreement = location.pathname === '/my-tokens/buy';
	const isOnSelectQuantity = location.pathname === '/my-tokens/buy/quantity-select';
	const isOnConfirmedStep = location.pathname === '/my-tokens/buy/confirmed-step';

	useEffect(() => {
		if (searchParams.has('agreement')) {
			dispatch({ type: SET_SELECTED_AGREEMENT_ID, payload: searchParams.get('agreement') });
		} else {
			dispatch({ type: SET_SELECTED_AGREEMENT_ID, payload: agreementsOptions[0].id });
		}
	}, [searchParams]);

	useEffect(() => {
		setDisableRootElementScroll(isHelpDialogOpen);
	}, [isHelpDialogOpen]);

	return (
		<BasePageFrame
			title={renderTitle()}
			subtitle={renderSubtitle()}
			endAdornment={
				<Box className="flex gap-4">
					<CrispCTAButton />
					<InfoButton
						text={intl.formatMessage({ id: 'mytokens.constructor.buy.help_dialog.button' })}
						onClick={() => dispatch({ type: TOGGLE_IS_HELP_DIALOG_OPEN })}
					/>
				</Box>
			}
			returnAction={handleGoBack}
			returnActionTitle={renderBackButtonTitle()}
			childContainerStyles={isOnSelectQuantity ? (isMobile ? 'mb-[160px]' : 'mb-[100px]') : undefined}
		>
			<Box className="flex flex-col w-full gap-4 xs:ml-0 md:ml-[5px]">
				<Card className="flex flex-col xs:p-4 md:p-6 h-full" elevation={0}>
					{!errorOnFetch && (isLoadingAgreementsOptions || isLoadingResponse) && <FullScreenLoading />}
					{errorOnFetch && (
						<ErrorAlert
							severity="error"
							action={
								<Button color="inherit" size="small" onClick={() => fetch()}>
									{intl.formatMessage({ id: 'common.retry.button' })}
								</Button>
							}
						>
							{intl.formatMessage({ id: 'common.loading_error_message.details' })}
						</ErrorAlert>
					)}
					{!errorOnFetch && agreementsOptions.length > 0 && (
						<Outlet context={[state, dispatch, fetch, totalsToRender]} />
					)}
				</Card>
			</Box>
			<Dialog
				open={isHelpDialogOpen}
				aria-labelledby="help-buy-tokens-dialog-title"
				aria-describedby="help-buy-tokens-dialog-description"
				className="flex justify-center items-center"
				onClose={() => dispatch({ type: TOGGLE_IS_HELP_DIALOG_OPEN })}
				maxWidth="xl"
			>
				<DialogTitle id="help-buy-tokens-dialog-title">
					{intl.formatMessage({ id: 'mytokens.constructor.buy.help_dialog.title' })}
				</DialogTitle>
				<DialogContent className="flex justify-center items-center" sx={{ width: '55vw', height: '55vh' }}>
					<Box className="flex mx-[6%] w-full h-full justify-end">
						<iframe
							style={{ borderRadius: '2em', width: '100%', height: '100%' }}
							height="260"
							src={
								'https://www.loom.com/embed/0e076b985a694932a4d41c659e02c5eb?sid=af3edac0-ec46-41e8-bd23-1724894a6dcd'
							}
							title="Acopiar video tutorial"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							allowFullScreen
						></iframe>
					</Box>
				</DialogContent>
				<DialogActions className="flex justify-center items-center">
					<Button onClick={() => dispatch({ type: TOGGLE_IS_HELP_DIALOG_OPEN })} variant="contained">
						{intl.formatMessage({ id: 'common.close' })}
					</Button>
				</DialogActions>
			</Dialog>
		</BasePageFrame>
	);
};

export default BuyTokens;
