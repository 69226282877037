import { Box, Button, Divider, Grid, Tooltip, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { useMobile } from '../../../hooks/use-mobile';
import FileFormPlaceholder from '../../file-form-placeholder';
import { BusinessName, StackedData, User } from '../../../types';
import { blue } from '@mui/material/colors';
import GenericTabFrame from '../../base-components/generic-tab-frame';
import StackDataDisplay from '../../base-components/stack-data-display';
import EditButton from '../../nuqlea-components/edit-button';
import filesClient from '../../../clients/filesClient';
import { Help, HelpOutline } from '@mui/icons-material';

export interface TaxProfileDetailsProps {
	switchEditMode: (isEditionMode: boolean) => void;
	handleDelete: (taxProfile: BusinessName) => void;
	taxProfile: BusinessName;
	user: User;
}

const TaxProfileDetails: React.FC<TaxProfileDetailsProps> = ({ switchEditMode, taxProfile, handleDelete, user }) => {
	const intl = useIntl();
	const isMobile = useMobile();

	const data: StackedData[] = [
		{
			title: intl.formatMessage({ id: 'settings.company.tax_profile_tab.tax_residence_input' }),
			description: taxProfile.invoice_address,
		},
		{
			title: intl.formatMessage({ id: 'settings.company.tax_profile_tab.iva_status_input' }),
			description: taxProfile.tax_status,
		},
		{
			title: intl.formatMessage({ id: 'settings.company.tax_profile_tab.iibb_status_input' }),
			description: taxProfile.iibb_status,
		},
	];

	return (
		<GenericTabFrame
			variant="card"
			buttons={[
				<EditButton key={'edit-button'} onClick={() => switchEditMode(true)}>
					{intl.formatMessage({ id: 'common.edit' })}
				</EditButton>,
				<Box display={'flex'} alignItems={'center'}>
					{!taxProfile.inUse ? (
						<Button
							key={'delete-button'}
							disabled={taxProfile.main || taxProfile.inUse ? true : false}
							onClick={() => handleDelete(taxProfile)}
						>
							{intl.formatMessage({ id: 'settings.company.tax_profile_tab.remove_tax_profile' })}
						</Button>
					) : (
						<Tooltip title={intl.formatMessage({ id: 'settings.company.tax_profile_tab.inUse' })}>
							<Button
								key={'delete-button'}
								disabled
								onClick={() => handleDelete(taxProfile)}
								sx={{ pointerEvents: 'all !important' }}
							>
								{intl.formatMessage({ id: 'settings.company.tax_profile_tab.remove_tax_profile' })}
							</Button>
						</Tooltip>
					)}
				</Box>,
			]}
		>
			<StackDataDisplay data={data}></StackDataDisplay>

			<Grid item xs={12}>
				<Typography className="mt-10 font-extrabold">{intl.formatMessage({ id: 'common.docs' })}</Typography>
			</Grid>
			<Grid item xs={12}>
				<Divider className="my-4" orientation="horizontal"></Divider>
			</Grid>

			<Grid container spacing={1} width={'100%'}>
				{taxProfile.files &&
					taxProfile.files.map((file: any) => {
						let adaptedFile = { ...file };

						adaptedFile.name = file.name;

						return (
							<Grid key={file.id} item xs={6}>
								<FileFormPlaceholder
									googleDriveId="werwer"
									user={user}
									file={adaptedFile}
									previewCallback={filesClient.downloadMetadataFile}
									downloadCallback={filesClient.downloadBinaryFile}
								></FileFormPlaceholder>
							</Grid>
						);
					})}
			</Grid>
		</GenericTabFrame>
	);
};

export default TaxProfileDetails;
