import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography, Button, ThemeProvider, Box } from '@mui/material';
import ProjectService from './projectsService';
import PageContainer from '../page-container';
import { useMobile } from '../../hooks/use-mobile';
import { useIntl } from 'react-intl';
import { IProjectData } from './projectTypes';
import ProjectsEmptyState from './empty-state/projects-empty-state';
import addressHelper from '../../helpers/addressHelper';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ProjectList from './projectList';
import { useRecoilValue } from 'recoil';
import userState from '../../atoms/user-state';
import BasePageFrame from '../base-components/base-page-frame';
import InfoMarker from '../info-marker';

const Projects = () => {
	const [isLoading, setIsLoading] = useState(true);
	const isMobile: boolean = useMobile();
	const navigate = useNavigate();
	const user = useRecoilValue(userState);

	const intl = useIntl();
	const [projects, setProjects] = useState<IProjectData[]>([]);
	const [showEmptyState, setShowEmptyState] = useState<boolean>(false);

	const handleRedirect = (id?: number, isEditing?: boolean) => {
		if (id) {
			navigate(`/projects/edit/${id}`, { state: { isEditingParam: isEditing } });
		} else {
			navigate('/projects/new');
		}
	};

	const createConstructorAccount = async () => {
		try {
			await ProjectService.createConstructorAccount(user);
			setIsLoading(true);
			fetchProjects().finally(() => setIsLoading(false));
		} catch (error) {
			console.error(error);
		}
	};

	const fetchProjects = useCallback(async () => {
		const projectData = await ProjectService.getProjects(user);

		if (projectData?.account === null) {
			await createConstructorAccount();
		}

		const projects: IProjectData[] =
			projectData.projects?.data.map((project: any) => ({
				id: project.id,
				name: project.name,
				address: project.full_address
					? addressHelper.formatAddress({
							address: project.full_address.address,
							city: project.full_address.city,
							province: project.full_address.province,
					  })
					: '',
				type: project.type ? project.type : '',
				status: project.state !== null ? project.state : 1,
				status_label: project.state !== null ? (project.state === 2 ? 'Pausado' : project.state === 1 ? 'Activo' : 'Terminado') : 'Activo',
			})) || [];
			
		setProjects(projects);

		if (projects.length === 0) {
			setShowEmptyState(true);
		}
	}, []);

	useEffect(() => {
		try {
			let mounted = true;
			fetchProjects().finally(() => setIsLoading(false));
			return () => {
				mounted = false;
			};
		} catch (error) {
			console.error(error);
		}
	}, []);

	return (
		<BasePageFrame
			title={intl.formatMessage({ id: 'common.my_projects.title' })}
			subtitle={intl.formatMessage({ id: `common.my_projects.subtitle` })}
			endAdornment={
				!showEmptyState &&
				!isLoading && (
					<Button onClick={() => handleRedirect()} id="projects__new-project-button">
						{intl.formatMessage({ id: `common.my_projects.new_project_button` })}
					</Button>
				)
			}
			transparent
		>
			<Grid container>
				<Grid item xs={12}>
					{showEmptyState ? (
						<ProjectsEmptyState user={user} callback={handleRedirect} />
					) : (
						<ProjectList intl={intl} projects={projects} handleRedirect={handleRedirect} isLoading={isLoading} />
					)}
				</Grid>
			</Grid>
		</BasePageFrame>
	);
};

export default Projects;
