import { Box, Button, Grid, Paper, PaperProps, Typography } from '@mui/material';
import ModalContainer from './modal-container';
import { useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';

interface GenericConfirmationModalProps extends PaperProps {
	title?: string;
	titleAlignment?: 'left' | 'center' | 'right';
	isLoading?: boolean;	
	message: string;
	messageStyle?: React.CSSProperties;
	primaryButtonText: string;
	primaryButtonCallback: () => void;
	secondaryButtonText: string;
	secondaryButtonCallback: () => void;
	buttonContainerStyle?: React.CSSProperties
}

const GenericConfirmationModal: React.FC<GenericConfirmationModalProps> = ({
	title,
	titleAlignment,
	isLoading,
	message,
	messageStyle,
	primaryButtonText,
	secondaryButtonText,
	primaryButtonCallback,
	secondaryButtonCallback,
	buttonContainerStyle,
	...props
}) => {
	const intl = useIntl();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		isLoading && setLoading(isLoading);
	}, [isLoading]);

	return (
		<ModalContainer>
			<Paper sx={{ height: '220px', width: '400px', py: '35px', px: '70px', ...props.sx }}>
				<Grid container display={'flex'} justifyContent={'space-between'} spacing={3}>
					{title && (
						<Grid textAlign={titleAlignment ?? 'center'} item xs={12}>
							<Typography variant="h6" fontWeight={700}>
								{title}
							</Typography>
						</Grid>
					)}
					<Grid item xs={12}>
						<Typography sx={messageStyle}>{message}</Typography>
					</Grid>
					<Grid item xs={12}>
						<Box sx={{display: 'flex', justifyContent: 'space-between', ...buttonContainerStyle}}>
							<Button sx={{ fontSize: '14px', width: '115px' }} onClick={secondaryButtonCallback} variant="outlined">
								{secondaryButtonText}
							</Button>
							<LoadingButton
								loading={loading}
								sx={{ fontSize: '14px', width: '115px' }}
								onClick={primaryButtonCallback}
								variant="contained"
							>
								{primaryButtonText}
							</LoadingButton>
						</Box>
					</Grid>
				</Grid>
			</Paper>
		</ModalContainer>
	);
};

export default GenericConfirmationModal;
