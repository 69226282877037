import React from 'react';
import { Box, Card, CardContent, Divider, Grid, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { DollarValueInPesos } from '../../types';
import { formatNumberWithCurrency } from '../../utils/data-utils';

interface Props {
	dollarType: string;
	dollarsMap: Map<string, DollarValueInPesos>;
	isFirstDollarCardWithDate: boolean;
	lastUpdateDate: Date;
}

const DollarValueCard = (props: Props) => {
	const { dollarType, dollarsMap, isFirstDollarCardWithDate, lastUpdateDate } = props;
	const intl = useIntl();

	return (
		<Card elevation={0}>
			<CardContent
				sx={{
					display: 'flex',
					minHeight: 89,
					maxHeight: 89,
					'&: last-child': { paddingBottom: '16px' },
					...(isFirstDollarCardWithDate ? { flexDirection: 'column' } : {}),
				}}
			>
				{isFirstDollarCardWithDate ? (
					<>
						<Typography sx={{ pl: 2 }} variant="h6" gutterBottom>
							{intl.formatMessage({ id: 'common.reference_prices.currency_value' })}
						</Typography>
						<Typography sx={{ pl: 2 }} variant='caption'>
							{`${intl.formatMessage({ id: `common.reference_prices.dollar_source` })}
							${intl.formatDate(lastUpdateDate, { day: 'numeric', month: 'numeric', year: 'numeric' }) /*always render date DD/MM/YYYY*/}
							${dollarsMap.size > 0 ? ' - ' + intl.formatTime(lastUpdateDate, { hour: 'numeric', minute: 'numeric' }) : '' /*render time only if dollarsMap is loaded HH:MM am/pm */}`}
						</Typography>
					</>
				) : (
					<Grid container>
						<Grid item xs={6} md={12} xl={4} alignItems={'center'} justifyContent={'center'} sx={{ display: 'flex' }}>
							<Typography variant='h6' marginRight={1}>
								{intl.formatMessage({ id: `common.reference_prices.${dollarType}_dollar` })}
							</Typography>
						</Grid>
						<Grid item xs={6} md={12} xl={8} alignItems={'center'} justifyContent={'end'} sx={{ display: 'flex' }}>
							<Grid container px={2} justifyContent={'space-between'} direction={'column'}>
								<Grid item alignItems={'center'} justifyContent={'start'} >
									<Stack direction={'row'} spacing={1} justifyContent={'space-between'}>
										<Typography variant='caption'>
											{intl.formatMessage({ id: 'common.reference_prices.buy' })}
										</Typography>

										<Typography variant='h6'>
											{formatNumberWithCurrency(dollarsMap.get(dollarType)?.compra, 2)}
										</Typography>
									</Stack>
								</Grid>
								<Grid item>
									<Divider />
								</Grid>
								<Grid item alignItems={'center'} justifyContent={'start'}>
									<Stack direction={'row'} spacing={1} justifyContent={'space-between'}>
										<Typography variant='caption'>
											{intl.formatMessage({ id: 'common.reference_prices.sell' })}
										</Typography>

										<Typography variant='h6'>
											{formatNumberWithCurrency(dollarsMap.get(dollarType)?.venta, 2)}
										</Typography>
									</Stack>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				)}
			</CardContent>
		</Card>
	);
};

export default DollarValueCard;
