import { SyntheticEvent, useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Grid, Typography, Skeleton, ThemeProvider, Box, Tab, Paper, styled, Stack, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ProjectService, { ExternalComponents } from '../projectsService';
import GCSService, { IGCSFile } from '../projectFilesService';
import PageContainer from '../../page-container';
import { useIntl } from 'react-intl';
import { BusinessFile } from '../../../types';
import { IProjectDetailData } from '../projectTypes';
import ProjectSummary from '../summary/projectSummary';
import ProjectForm from '../form/projectForm';
import toast from 'react-hot-toast';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import SkeletonInput from '../../skeleton-input';
import { useMobile } from '../../../hooks/use-mobile';
import userState from '../../../atoms/user-state';
import { useRecoilValue } from 'recoil';
import BasePageFrame from '../../base-components/base-page-frame';

const ProjectDetail = () => {
	const { id } = useParams();
	const location = useLocation();
	const isEditingParam = location.state?.isEditingParam ?? false;

	const [isLoadingFiles, setIsLoadingFiles] = useState(true);
	const navigate = useNavigate();
	const intl = useIntl();
	const isMobile: boolean = useMobile();
	const user = useRecoilValue(userState);

	const [isLoadingProject, setIsLoadingProject] = useState(true);
	const [project, setProject] = useState<IProjectDetailData | null>(null);
	const [projectFiles, setProjectFiles] = useState<BusinessFile[] | null>(null);
	const [isEditing, setIsEditing] = useState(isEditingParam ?? false);
	const [isCreating, setIsCreating] = useState(false);
	const [value, setValue] = useState('1');
	const [showTabs, setShowTabs] = useState(!isEditingParam);

	const StyledIFrame = styled('iframe')(({ theme }) => ({
		borderStyle: 'none',
		width: '100%',
		height: '64vh',
	}));

	const handleTabChange = (event: SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};

	const handleEdit = (isEditing: boolean) => {
		setIsEditing(isEditing);
	};

	const handleCancel = () => {
		if (isEditing) {
			if (id) {
				fetchProject(Number(id));
				fetchProjectFiles(Number(id));
			}
			setIsEditing(false);
		} else {
			navigate('/projects');
		}
	};

	const gotoProjects = () => {
		navigate('/projects');
	};

	const fetchProject = async (id: number) => {
		setProject(null);
		setIsLoadingProject(true);
		try {
			const projectData = await ProjectService.getProjectById(user, id);
			if (projectData) {
				const parsed = {
					id: projectData.project?.id,
					name: projectData.project?.name,
					construction_responsible: projectData.project?.construction_responsible,
					ns_construction_responsible_id: projectData.project?.ns_construction_responsible_id,
					purchase_responsible: projectData.project?.purchase_responsible,
					ns_purchase_responsible_id: projectData.project?.ns_purchase_responsible_id,
					address: projectData.project?.address,
					full_address: projectData.project?.full_address,
					type: projectData.project?.type,
					type_id: projectData.project?.type_id,
					financing_type: projectData.project?.financing_type,
					financing_type_id: projectData.project?.financing_type_id,
					description: projectData.project?.description,
					status: projectData.project?.state !== null ? projectData.project?.state : 1,
					status_label: projectData.project?.state !== null ? (projectData.project?.state === 2 ? 'Pausado' : projectData.project?.state === 1 ? 'Activo' : 'Terminado') : 'Activo',
				};

				setProject(parsed);
				setIsLoadingProject(false);
			}
		} catch (error) {
			console.error(error);
			setProject(null);
		}
	};

	const fetchProjectFiles = async (id: number) => {
		setIsLoadingFiles(true);
		setProjectFiles(null);
		try {
			const projectFiles = await GCSService.getProjectFiles(user, id);

			if (projectFiles.files && projectFiles.files.length > 0) {
				setProjectFiles(
					projectFiles.files.map((file: IGCSFile) => ({
						id: file.id,
						path: file.path,
						name: file.name.split('/').pop() ?? '',
					}))
				);
			}

			setIsLoadingFiles(false);
		} catch (error) {
			console.error(error);
			setProjectFiles(null);
			setIsLoadingFiles(false);
		}
	};

	useEffect(() => {
		let mounted = true;

		if (id) {
			fetchProject(Number(id));
			fetchProjectFiles(Number(id));
		} else {
			setIsLoadingProject(false);
		}

		window.addEventListener('message', (ev: MessageEvent<{ type: string; message: string }>) => {
			if (typeof ev.data !== 'object') return;
			if (!ev.data.type) return;
			if (ev.data.type !== 'open-list') return;
			if (!ev.data.message) return;

			handleReceiveMessage(ev.data.message as string);
		});

		return () => {
			mounted = false;
		};
	}, [id, user]);

	useEffect(() => {
		if (id) {
			setShowTabs(!isEditing);
			setIsCreating(false);
		} else {
			setShowTabs(false);
			setIsCreating(true);
		}
	}, [isEditing]);

	const handleReceiveMessage = (listId: string) => {
		if (!listId) return;

		navigate(`/list/${listId}`);
	};

	const renderSkeleton = () => {
		return (
			<Grid container sx={{ width: '100%' }}>
				<Grid item xs={12}>
					<Skeleton variant="rectangular" height={'700px'} width={'100%'} animation="wave" />
				</Grid>
			</Grid>
		);
	};

	const renderIframe = (src: string) => {
		return (
			<Grid item height={'100%'} xs={12}>
				<Paper elevation={0} sx={{ minHeight: '600px' }}>
					<StyledIFrame title="frame" name="frame" src={src}></StyledIFrame>
				</Paper>
			</Grid>
		);
	};

	const renderShowEditTab = () => {
		if (isLoadingProject) {
			return renderSkeleton();
		} else if (project) {
			return (
				<Grid item xs={12}>
					{isEditing ? (
						<ProjectForm
							user={user}
							project={project}
							projectFiles={projectFiles}
							handleCancel={handleCancel}
							callback={() => toast.success('El proyecto se actualizó correctamente.')}
						/>
					) : (
						<ProjectSummary
							user={user}
							project={project}
							projectFiles={projectFiles}
							handleEdit={handleEdit}
							handleCancel={gotoProjects}
							callback={gotoProjects}
							isLoadingFiles={isLoadingFiles}
						/>
					)}
				</Grid>
			);
		} else {
			return (
				<ProjectForm
					user={user}
					project={project}
					projectFiles={projectFiles}
					handleCancel={handleCancel}
					callback={gotoProjects}
				/>
			);
		}
	};

	const renderCalculationTab = () => {
		if (project) {
			return renderIframe(ProjectService.getExternalUrl(user, project.id!, ExternalComponents.Computation));
		} else {
			return renderSkeleton();
		}
	};

	const renderInputExplosionTab = () => {
		if (project) {
			return renderIframe(ProjectService.getExternalUrl(user, project.id!, ExternalComponents.Supplies));
		} else {
			return renderSkeleton();
		}
	};

	return (
		<BasePageFrame
			title={
				project ? (
					project.name
				) : id ? (
					<SkeletonInput width={'400px'} />
				) : (
					intl.formatMessage({ id: 'common.my_projects.title' })
				)
			}
			subtitle={id ? '' : intl.formatMessage({ id: 'common.my_projects.subtitle' })}
			returnAction={isCreating ? undefined : gotoProjects}
			transparent
		>
			<Grid container spacing={0} rowGap={0}>
				<Grid item xs={12}>
					<TabContext value={value}>
						{!isCreating && (
							<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
								<TabList onChange={handleTabChange}>
									<Tab
										label={
											!isEditing
												? intl.formatMessage({ id: 'projects.tabs.showTab' })
												: intl.formatMessage({ id: 'projects.tabs.editTab' })
										}
										value="1"
									/>
									{showTabs && <Tab label={intl.formatMessage({ id: 'projects.tabs.calculationTab' })} value="2" />}
									{showTabs && <Tab label={intl.formatMessage({ id: 'projects.tabs.inputExplosionTab' })} value="3" />}
								</TabList>
							</Box>
						)}
						<TabPanel key={'showEditTab'} value="1" sx={{ padding: '36px 0 0 0 ', height: '100%', width: '100%' }}>
							{renderShowEditTab()}
						</TabPanel>
						<TabPanel key={'calculationTab'} value="2" sx={{ padding: '36px 0 0 0 ', height: '100%', width: '100%' }}>
							{renderCalculationTab()}
						</TabPanel>
						<TabPanel
							key={'inputExplosionTab'}
							value="3"
							sx={{ padding: '36px 0 0 0 ', height: '100%', width: '100%' }}
						>
							{renderInputExplosionTab()}
						</TabPanel>
					</TabContext>
				</Grid>
			</Grid>
		</BasePageFrame>
	);
};

export default ProjectDetail;
