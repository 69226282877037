import { type ReactNode } from 'react';
import { createTheme, useMediaQuery } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import themes from '../../themes';
import { Theme } from '../../types';

interface Props {
    theme: Theme,
    children?: ReactNode
}

export const NuqleaThemeProvider = ({ theme, children }: Props) => {
    const currentTheme = themes[theme];
    const defaultComponentSize = useMediaQuery(currentTheme.breakpoints.down('xl')) ? 'small' : 'medium';

    return (
        <ThemeProvider 
            theme={() =>
                createTheme(currentTheme, {
                    ...currentTheme.components,
                    components: {
                        MuiChip: {
                            defaultProps: {
                                size: defaultComponentSize,
                            },
                        },
                        MuiTextField: {
                            defaultProps: {
                                size: defaultComponentSize,
                            },
                        },
                        MuiSelect: {
                            defaultProps: {
                                size: defaultComponentSize,
                            },
                        },
                        MuiRadio: {
                            defaultProps: {
                                size: defaultComponentSize,
                            },
                        },
                        MuiInput: {
                            defaultProps: {
                                size: defaultComponentSize,
                            },
                        },
                    },
                })
            }
        >
            {children}
        </ThemeProvider>
    );
};
