import { ExpandMore } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import {
	Box,
	Grid,
	Button,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Modal,
	Fade,
} from '@mui/material';
import { useIntl } from 'react-intl';
import GenericConfirmationModal from '../../modals/generic-confirmation-modal';
import { BusinessDetails, BusinessName, User } from '../../../types';
import businessNameClient from '../../../clients/businessNameClient';
import CreditProfileDetails from './credit-profile-details';

interface creditProfileTabProps {
	credProfiles?: BusinessDetails[];
	businessNames?: BusinessName[];
	editCreditProfile?: (edition: boolean, creditProfile?: BusinessDetails) => void;
	updateCreditProfilesList: () => void;
	clearCreditProfilesToEdit: () => void;
	token: string;
	user: User;
	orphanBusinessNames: BusinessName[] | undefined;
}

const CreditProfileTab: React.FC<creditProfileTabProps> = ({
	credProfiles,
	businessNames,
	editCreditProfile,
	token,
	user,
	updateCreditProfilesList,
	clearCreditProfilesToEdit,
	orphanBusinessNames,
}) => {
	const intl = useIntl();

	const [addCuitButtonEnabled, setAddCuitButtonEnabled] = useState(
		orphanBusinessNames && orphanBusinessNames.length > 0 ? true : false
	);

	const [creditProfiles, setCreditProfiles] = useState(credProfiles);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [creditProfileToDelete, setCreditProfileToDelete] = useState<BusinessDetails>();
	const [isDeleting, setIsDeleting] = useState(false);

	const switchEditMode = (edition: boolean, taxProfileToEdit?: BusinessDetails) => {
		editCreditProfile && editCreditProfile(edition, taxProfileToEdit);
	};

	const onDeleteModal = () => {
		creditProfileToDelete && handleDelete(creditProfileToDelete);
	};

	const onCancelModal = () => {
		setShowDeleteModal(false);
		setCreditProfileToDelete(undefined);
	};

	const handleDelete = async (creditProfileToDelete: BusinessDetails) => {
		setIsDeleting(true);
		await businessNameClient.deleteBusinessDetails({ id: String(creditProfileToDelete.id), user: user });
		setIsDeleting(false);
		setShowDeleteModal(false);
		clearCreditProfilesToEdit();
		updateCreditProfilesList();
	};

	useEffect(() => {
		setCreditProfiles(credProfiles);
	}, [credProfiles]);

	return (
		<Box>
			<Grid container spacing={2}>
				<Grid item xs={12} className="flex justify-end">
					<Button
						disabled={!addCuitButtonEnabled}
						onClick={() => switchEditMode(true, undefined)}
						className="text-base"
						size="small"
					>
						{intl.formatMessage({ id: 'common.add_CUIT' })}
					</Button>
				</Grid>

				{creditProfiles?.map((profile: BusinessDetails) => {
					return (
						<Grid item xs={12} key={profile.id}>
							<Accordion defaultExpanded={creditProfiles.length === 1}>
								<AccordionSummary expandIcon={<ExpandMore className="w-8 h-8" />}>
									<Box display={'flex'}>
										<Box display={'flex'} flexDirection="column">
											<Typography variant="h6" className="pl-7" fontWeight={700}>
												{businessNames?.find((i) => i.id === profile.bussiness_name_id)?.business_social}
											</Typography>
											<Typography variant="h6" className="pl-7" fontWeight={700}>
												{businessNames?.find((i) => i.id === profile.bussiness_name_id)?.cuit}
											</Typography>
										</Box>
									</Box>
								</AccordionSummary>
								<AccordionDetails>
									<CreditProfileDetails
										handleDelete={(creditProfile: BusinessDetails) => {
											setCreditProfileToDelete(creditProfile);
											setShowDeleteModal(true);
										}}
										switchEditMode={(edition) => switchEditMode(edition, profile)}
										creditProfile={profile}
										user={user}
									></CreditProfileDetails>
								</AccordionDetails>
							</Accordion>
						</Grid>
					);
				})}
			</Grid>
			<Modal open={showDeleteModal} onKeyDown={(event) => event.key === 'Escape' && setShowDeleteModal(false)}>
				<Fade in={showDeleteModal}>
					<Box>
						<GenericConfirmationModal
							isLoading={isDeleting}
							title={intl.formatMessage({ id: 'settings.company.tax_profile_tab.remove_tax_profile' })}
							message={intl.formatMessage({ id: 'settings.company.tax_profile_tab.remove_tax_profile_confirmation' })}
							primaryButtonText={intl.formatMessage({
								id: 'common.delete',
							})}
							primaryButtonCallback={onDeleteModal}
							secondaryButtonText={intl.formatMessage({
								id: 'common.cancel',
							})}
							secondaryButtonCallback={onCancelModal}
						></GenericConfirmationModal>
					</Box>
				</Fade>
			</Modal>
		</Box>
	);
};

export default CreditProfileTab;
