import {
	Accordion,
	AccordionSummary,
	Box,
	Card,
	CardContent,
	Grid,
	Link,
	Skeleton,
	Stack,
	Typography,
	debounce,
} from '@mui/material';
import {
	Document,
	FilesList,
	Response,
	TokenDetailStatus,
	StockSafekeeping,
	Role,
	BusinessName,
	AgreementConditionTypes,
	SellerWithDocuments,
	StackedData,
} from '../../../types';
import TableCardTreeView from '../../table-card-tree-view';
import { Upload, ExpandMore } from '@mui/icons-material';
import { relatedFiles } from '../../details/columns';
import FileUploadDialog from '../../details/dialogs/file-upload-dialog';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import client from '../../../clients/client';
import businessNameClient from '../../../clients/businessNameClient';
import { useIntl } from 'react-intl';
import Error from '../../error';
import InfoMarker from '../../info-marker';
import NuqleaAlert from '../../nuqlea-alert';
import SideModalContainer from '../../modals/side-modal-container';
import { useMobile } from '../../../hooks/use-mobile';
import TaxProfileForm from '../../settings/organization/shared-components/tax-profile-form';
import { formatNumberLocale } from '../../../utils/data-utils';
import userState from '../../../atoms/user-state';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import SellerPaymentInfo from '../../details/dialogs/seller-payment-info';
import disableRootElementScrollState from '../../../atoms/disable-root-element-scroll-state';
import BasePageFrame from '../../base-components/base-page-frame';
import StackDataDisplay from '../../base-components/stack-data-display';
import StatusCardV2, { Status } from '../../status-card-v2';
import { Action } from '../../card';
import MapsDialog from '../buy-tokens/maps-dialog';
import { ShippingZone } from '../buy-tokens/reducer';

const allowedFileTypes = [
	{ id: 7, name: 'Orden de compra' },
	{ id: 9, name: 'Comprobante de pago' },
	{ id: 10, name: 'Comprobante de retenciones' },
	{ id: 11, name: 'Órden de pago' },
	{ id: 22, name: 'Cronograma de pago' },
];

const getDelivery = (conditions: []) => {
	const delivery: any = conditions.find(
		(item: any) =>
			item.condition_type_id === AgreementConditionTypes.SHIPPING ||
			item.condition_type_id === AgreementConditionTypes.WITHDRAWAL
	);
	return delivery ?? { agreement_condition_type_label: '-', agreement_condition_zone_label: '-' };
};

const getFinancingCondition = (conditions: []) => {
	const financing: any = conditions.find((item: any) => item.condition_type_id === AgreementConditionTypes.FINANCING);
	return financing ? financing.name : 'Sin financiación';
};

const TokenDetailViewConstructor = () => {
	const { id } = useParams();
	const user = useRecoilValue(userState);
	const setDisableRootElementScroll = useSetRecoilState(disableRootElementScrollState);
	const intl = useIntl();
	const isMobile = useMobile();
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(true);
	const [showError, setShowError] = useState(false);
	const [stockSafekeepingResponse, setStockSafekeepingResponse] = useState<Response<StockSafekeeping>>();
	const [sSks, setSSks] = useState<StockSafekeeping>();
	const [agreementConditions, setAgreementConditions] = useState<any>([]);
	const [fileUploadDialogOpen, setFileUploadDialogOpen] = useState(false);
	const [allDocuments, setAllDocuments] = useState<Document[]>([]);
	const [filesResponse, setFilesResponse] = useState<Response<FilesList>>();
	const [stockSafekeepingDetails, setStockSafekeepingDetails] = useState<StackedData[]>([]);
	const [counts, setCounts] = useState<Status[]>();
	const [mapsDialogOpen, setMapsDialogOpen] = useState(false);
	const [shippingZones, setShippingZones] = useState<ShippingZone[]>([]);
	const [shippingZone, setShippingZone] = useState<ShippingZone>();
	const [taxProfileForm, setTaxProfileForm] = useState<boolean>(false);
	const [businessName, setBusinessName] = useState<BusinessName>();
	const [isSellerInfoOpen, setIsSellerInfoOpen] = useState<boolean>(false);
	const [sellerInfo, setSellerInfo] = useState<SellerWithDocuments>();
	const [actionButtons, setActionButtons] = useState<Action[]>();
	const [showInvalidInvoiceProfile, setShowInvalidInvoiceProfile] = useState(false);
	const [showBonusInfo, setShowBonusInfo] = useState(false);

	const handleGoBack = () => {
		navigate(-1);
	};

	const getPriceBonus = (conditions: []) => {
		const priceBonus: any = conditions.find((item: any) => item.condition_type_id === AgreementConditionTypes.PRICE);

		return priceBonus?.name;
	};

	const fetchStockSafekeeping = debounce(async (fetchDocs: Boolean) => {
		setStockSafekeepingResponse(undefined);

		const response = await client.getStockSafekeeping({ token: user.token, sSkId: id, role: Role.CONSTRUCTOR });
		if (response && response.sSks) {
			setStockSafekeepingResponse(response);
			getAgreementConditionsForSsk(response.sSks.id);
			fetchSellerDocuments(response.sSks.seller);

			if (fetchDocs) {
				fetchListDocuments(response.sSks.order_id, id);
			}

			checkStatus(response.sSks);

			configActionButtons(response.sSks);

			if (!response.sSks.valid_invoice_profile) {
				businessNameClient.getBusinessName({ user: user, id: response.sSks.company_id }).then((response) => {
					if (response && response.length > 0) {
						setBusinessName(response[0]);
					}
				});
			}
		}

		setIsLoading(false);
	}, 250);

	const getAgreementConditionsForSsk = async (sskid: string) => {
		try {
			const response = await client.getAgreementConditionForSsk({
				token: user.token,
				sSkId: sskid,
			});
			setAgreementConditions(response.conditions);
		} catch (error) { }
	};

	const fetchListDocuments = debounce((orderId, sSkId) => {
		setFilesResponse(undefined);

		client
			.getTokenDocuments({ orderId, sSkId, user })
			.then((data) => {
				setFilesResponse({ data });
				const filteredDocs = data?.map((file: Document) => file);
				setAllDocuments(filteredDocs ?? []);
			})
			.catch(() => setFilesResponse({ error: true }));
	}, 250);

	const fetchSellerDocuments = debounce((sellerId: string) => {
		client.getSellerWithDocuments({ id: sellerId, user }).then((data) => {
			setSellerInfo(data);
		});
	}, 250);

	const checkStatus = (sSk: any) => {
		setCounts([
			{ label: TokenDetailStatus.REQUEST_SENT, active: false, completed: sSk?.Estado_orden > 0 },
			{
				label: TokenDetailStatus.INVOICE_AWAIT,
				active: sSk?.Estado_orden === 1,
				completed: sSk?.Estado_orden > 1,
				tooltip: intl.formatMessage({ id: 'mytokens.constructor.detail.invoice_awaiting' }),
			},
			{
				label: TokenDetailStatus.PAYMENT_SENT,
				active: sSk?.Estado_orden === 2,
				completed: sSk?.Estado_orden > 2,
				tooltip: intl.formatMessage({ id: 'mytokens.constructor.detail.payment_sent' }),
			},
			{ label: TokenDetailStatus.FINISHED, active: sSk?.Estado_orden === 4, completed: sSk?.Estado_orden > 3 },
		]);
	};

	const configActionButtons = (sSk: any) => {
		let actionButtons: any = [];
		if (sSk.Estado_orden === 2) {
			actionButtons = [
				{
					displayName: intl.formatMessage({ id: 'mytokens.constructor.detail.upload_file.payment' }),
					icon: <Upload />,
					type: 'Button',
					onClick: () => setFileUploadDialogOpen(true),
				},
				{
					displayName: intl.formatMessage({ id: 'mytokens.constructor.detail.payment_info' }),
					type: 'Button',
					variant: 'text',
					onClick: () => setIsSellerInfoOpen(true),
				},
			];
		} else {
			actionButtons = [
				{
					displayName: intl.formatMessage({ id: 'common.upload_file' }),
					icon: <Upload />,
					type: 'Button',
					onClick: () => setFileUploadDialogOpen(true),
				},
			];
		}

		setActionButtons(actionButtons);
	};

	useEffect(() => {
		try {
			fetchStockSafekeeping(true);
		} catch (error) {
			console.error(error);
			setShowError(true);
		}
	}, [id]);

	useEffect(() => {
		setDisableRootElementScroll(taxProfileForm || isSellerInfoOpen || fileUploadDialogOpen);
	}, [taxProfileForm, isSellerInfoOpen, fileUploadDialogOpen]);

	useEffect(() => {
		if (stockSafekeepingResponse) {
			const { sSks } = stockSafekeepingResponse as any;
			const delivery = getDelivery(agreementConditions);
			const shippingZone = {
				zone_id: delivery?.agreement_condition_zone_id,
				zone_label: delivery?.agreement_condition_zone_label,
				agreement_condition_id: delivery?.id,
				zone_url: delivery?.agreement_condition_zone_url,
			};

			setShowInvalidInvoiceProfile(!sSks?.valid_invoice_profile);
			setShowBonusInfo(getPriceBonus(agreementConditions) ? true : false);
			setSSks(sSks);
			setShippingZone(shippingZone);
			setShippingZones([shippingZone]);
			setStockSafekeepingDetails([
				{
					title: intl.formatMessage({ id: 'mytokens.acquisition_details.amount' }),
					description: `${formatNumberLocale(sSks?.quantity ?? 0)} tokens`,
				},
				{
					title: intl.formatMessage({ id: 'mytokens.acquisition_details.total_cost' }),
					description: intl.formatNumber(sSks?.Monto_final, {
						style: 'currency',
						currency: 'ARS',
						currencyDisplay: 'narrowSymbol',
					}),
				},
				{
					title: intl.formatMessage({ id: 'mytokens.constructor.acquisition_details.financing' }),
					description: agreementConditions?.length ? getFinancingCondition(agreementConditions) : '-',
				},
				{
					title: intl.formatMessage({ id: 'mytokens.acquisition_details.business_name' }),
					description: `${sSks?.business_social} | ${sSks?.the_cuit}`,
				},
				{
					title:
						delivery.condition_type_id === AgreementConditionTypes.SHIPPING
							? intl.formatMessage({ id: 'mytokens.acquisition_details.shipping_zone' })
							: delivery.agreement_condition_type_label,
					description: delivery.agreement_condition_zone_label,
					objectDesc:
						delivery?.condition_type_id && delivery?.condition_type_id === AgreementConditionTypes.SHIPPING ? (
							<Box onClick={() => setMapsDialogOpen(true)} sx={{ cursor: 'pointer' }}>
								<Typography variant={'body2'} color={'blue'} textAlign={'end'}>
									{delivery?.agreement_condition_zone_label}
								</Typography>
							</Box>
						) : (
							<Link
								sx={{ textDecoration: 'none', '& :visited': { color: 'blue' } }}
								href={shippingZone?.zone_url ?? ''}
								target="_blank"
							>
								<Typography variant={'body2'} color={'blue'} textAlign={'end'}>
									{delivery?.agreement_condition_zone_label}
								</Typography>
							</Link>
						),
				},
				{
					title: intl.formatMessage({ id: 'mytokens.constructor.acquisition_details.seller' }),
					description: '-',
					objectDesc: (
						<Typography
							onClick={() => setIsSellerInfoOpen(true)}
							variant={'body2'}
							color={'blue'}
							textAlign={'end'}
							sx={{ cursor: 'pointer' }}
						>
							{sellerInfo?.seller.seller}
						</Typography>
					),
				},
			]);
		}
	}, [stockSafekeepingResponse, agreementConditions, sellerInfo]);

	const forceInsertNewFile = (newFile: Document) => {
		if (newFile.parent_documents_ids) {
			const allRelations = newFile.parent_documents_ids.split(',');
			let allNewDocs = allDocuments;

			allRelations.map((fileId: string) => {
				const foundIndex = allNewDocs.findIndex((el) => el.file_id === fileId);
				allNewDocs[foundIndex].children = [...allNewDocs[foundIndex].children, newFile];

				return fileId;
			});

			setAllDocuments(allNewDocs);
		} else {
			setAllDocuments([...allDocuments, newFile]);
		}

		if (newFile.file_type_id === 9 && sSks?.Estado_orden === 2) {
			setCounts([
				{ label: TokenDetailStatus.REQUEST_SENT, active: false, completed: true },
				{ label: TokenDetailStatus.INVOICE_AWAIT, active: false, completed: true },
				{ label: TokenDetailStatus.PAYMENT_SENT, active: false, completed: true },
				{ label: TokenDetailStatus.FINISHED, active: true, completed: false },
			]);
		}
	};

	const DetailsCard = (
		<Card elevation={0}>
			<CardContent sx={{ p: 4 }}>
				<Grid container spacing={2} justifyContent="space-between">
					{!isMobile && (
						<Grid item xs={12} md={6}>
							<Box>
								<Typography variant={'h5'}>{intl.formatMessage({ id: 'mytokens.redeem_request_details' })}</Typography>
							</Box>
							<Box>
								<Stack direction="row" spacing={1}>
									<Typography variant={'body2'}>{intl.formatDate(sSks?.created_at, { timeZone: 'UTC' })}</Typography>
								</Stack>
							</Box>
							<Stack direction={'column'} mt={2} spacing={2} maxWidth={'80%'}>
								{showBonusInfo && (
									<Box>
										<InfoMarker
											colorScheme="success"
											icon="gift"
											message={getPriceBonus(agreementConditions)}
											sx={{ justifyContent: 'start' }}
										></InfoMarker>
									</Box>
								)}
								{showInvalidInvoiceProfile && (
									<Box>
										<NuqleaAlert
											action={() => {
												setTaxProfileForm(true);
											}}
											actionTitle={intl.formatMessage({ id: 'common.complete_action' })}
											width={'100%'}
										>
											{intl.formatMessage({ id: 'mytokens.acquisition_details.invalid_invoice_profile' })}
										</NuqleaAlert>
									</Box>
								)}
							</Stack>
						</Grid>
					)}
					<Grid item xs={12} md={6}>
						<StackDataDisplay data={stockSafekeepingDetails} descriptionTextAlign="right"></StackDataDisplay>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);

	const RelatedDocuments = (
		<TableCardTreeView
			loading={!filesResponse}
			title={!isMobile ? intl.formatMessage({ id: 'list.seller.details.related_files.title' }) : undefined}
			layoutColumns={6}
			borderRadius={1}
			columns={relatedFiles}
			relatedColumns={relatedFiles}
			rows={allDocuments?.map((document) => ({ ...document, user, list_id: '2bfc5999' }))}
			actions={actionButtons}
			elevation={0}
		></TableCardTreeView>
	);

	return (
		<BasePageFrame
			title={intl.formatMessage({ id: 'mytokens.constructor.detail.title' })}
			returnAction={handleGoBack}
			transparent
		>
			{showError ? (
				<Error />
			) : (
				<Grid container spacing={2}>
					<Grid item xs={12}>
						{isLoading ? (
							<Skeleton variant="rectangular" height={200} />
						) : (
							<StatusCardV2 titleLocation="center" statuses={counts || []} elevation={0} />
						)}
					</Grid>
					<Grid item xs={12}>
						{isLoading ? (
							<Skeleton variant="rectangular" height={300} />
						) : !isMobile ? (
							DetailsCard
						) : (
							<Accordion elevation={0}>
								<AccordionSummary id="details" expandIcon={<ExpandMore />}>
									<Box>
										<Typography variant={'h5'}>
											{intl.formatMessage({ id: 'mytokens.redeem_request_details' })}
										</Typography>

										<Stack direction="row" spacing={1}>
											<Typography variant={'body2'}>
												{intl.formatDate(sSks?.created_at, { timeZone: 'UTC' })}
											</Typography>
										</Stack>
									</Box>
								</AccordionSummary>
								{DetailsCard}
							</Accordion>
						)}
					</Grid>
					<Grid item xs={12}>
						{isLoading ? (
							<Skeleton variant="rectangular" height={300} />
						) : !isMobile ? (
							RelatedDocuments
						) : (
							<Accordion elevation={0}>
								<AccordionSummary id="details" expandIcon={<ExpandMore />}>
									<Typography variant={'h5'}>
										{intl.formatMessage({ id: 'list.seller.details.related_files.title' })}
									</Typography>
								</AccordionSummary>
								{RelatedDocuments}
							</Accordion>
						)}
					</Grid>
				</Grid>
			)}

			<FileUploadDialog
				tokenization={true}
				dataTokenization={sSks}
				open={fileUploadDialogOpen}
				selectedType={sSks?.Estado_orden === 2 ? 9 : undefined}
				types={allowedFileTypes.filter((fileType) => {
					if ([13, 14, 15].includes(fileType.id)) {
						return (
							allDocuments?.filter(
								(document) => document.label_file_type === 'Factura' || document.label_file_type === 'Proforma'
							).length > 0
						);
					}

					return true;
				})}
				documents={
					allDocuments?.filter(
						(document) => document.label_file_type === 'Factura' || document.label_file_type === 'Proforma'
					) || []
				}
				user={user}
				onClose={(event, reason, newFile) => {
					setFileUploadDialogOpen(false);

					if (newFile) forceInsertNewFile(newFile);

					if (!['cancelButtonClick', 'backdropClick'].includes(reason ?? '') && newFile)
						fetchListDocuments(sSks?.order_id, id);
				}}
			/>

			<MapsDialog
				deliveriesConditions={agreementConditions?.find(
					(item: any) =>
						item.condition_type_id === AgreementConditionTypes.SHIPPING ||
						item.condition_type_id === AgreementConditionTypes.WITHDRAWAL
				)}
				open={mapsDialogOpen}
				onClose={() => setMapsDialogOpen(false)}
				zones={shippingZones}
				setZone={() => { }}
				initialZone={shippingZone}
				isReadOnly={true}
			/>

			<SideModalContainer
				sx={{ width: '430px' }}
				slideDirection={isMobile ? 'up' : 'left'}
				modalPosition={isMobile ? 'center' : 'right'}
				modalContentAlign={isMobile ? 'bottom' : undefined}
				isOpen={taxProfileForm}
				onClose={() => setTaxProfileForm(false)}
			>
				<TaxProfileForm
					callback={() => {
						setTaxProfileForm(false);
						fetchStockSafekeeping(false);
					}}
					businessNameToEdit={businessName}
					organizationId={user.id_external}
					token={user.token}
					creator={user}
					isModal={true}
				></TaxProfileForm>
			</SideModalContainer>

			{sellerInfo && (
				<SellerPaymentInfo
					open={isSellerInfoOpen}
					sellerData={{ name: sellerInfo.seller.seller, taxId: sellerInfo.seller.cuit, image: sellerInfo.seller.logo }}
					files={sellerInfo.documents
						?.filter((item: any) => item.visibility === 'all' || item.visibility === 'token')
						.reduce((acc: any, file: any) => {
							const key = file.type;
							const curGroup = acc[key] ?? [];

							return { ...acc, [key]: [...curGroup, file] };
						}, {})}
					user={user}
					onClose={() => setIsSellerInfoOpen(false)}
				/>
			)}
		</BasePageFrame>
	);
};

export default TokenDetailViewConstructor;
