import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {
	MenuItem,
	Button,
	Menu,
	IconButton,
	Box,
	AppBar,
	Toolbar,
	ListSubheader,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Divider,
	debounce,
	Typography,
	Paper,
	useTheme,
	useMediaQuery,
} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import WalletIcon from '@mui/icons-material/Wallet';
import HomeIcon from '@mui/icons-material/Home';
import GroupsIcon from '@mui/icons-material/Groups';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SettingsIcon from '@mui/icons-material/Settings';
import AssignmentIcon from '@mui/icons-material/Assignment';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import MenuIcon from '@mui/icons-material/Menu';
import HelpIcon from '@mui/icons-material/Help';
import logo from '../assets/nuqlea-logo-black.svg';
import newDemo from '../assets/new-demo.png';
import { styled } from '@mui/material/styles';
import { useIntl } from 'react-intl';
import { Role, User, CollaboratorRole } from '../types';
import { useAuth0 } from '@auth0/auth0-react';
import ChangeDialog from './details/dialogs/change-dialog';
import HelpDialog from './details/dialogs/help-dialog/help-dialog';
import HelpDialogArchbee from './details/dialogs/help-dialog/help-dialog-archbee';
import AvatarUserMenu from './avatar-user-menu';
import { UserMenuOptions } from './avatar-user-menu';

import NotificationsBell from './notifications/notifications-bell';
import client from '../clients/client';
import userState from '../atoms/user-state';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import disableRootElementScrollState from '../atoms/disable-root-element-scroll-state';
import hideDraweMenuState from '../atoms/hide-drawer-menu-state';
import { useIsTablet } from '../hooks/use-isTablet';

interface UserMenu {
	action: Function;
	id: string;
	admin: boolean;
}
interface NavBarItem {
	title: string;
	icon: React.ReactElement;
	rightIcon?: React.ReactElement;
	path?: string;
	action?: Function;
	hide?: boolean;
}
interface Props {
	remoteConfig: any;
}

const handleOpenHelp = (user: User | null, onOpen: Function) => {
	if (user?.type_external === Role.SELLER) {
		onOpen();
	} else {
		window.open('https://help.nuqlea.com', '_blank');
	}
};

const getOptionsSlice = (user: User | null, matchesXs: boolean) => {
	if (!matchesXs) {
		if (user?.type_external === Role.SELLER) {
			return -1;
		} else {
			return -2;
		}
	}
}

const ListStyled = styled(List)({
	// selected and (selected + hover) states
	'&& .Mui-selected': {
		'& *': {
			color: 'black',
			fontWeight: '500',
		},
		'&:hover': {
			backgroundColor: '#0000000a',
		},
	},
});

const isSelected = (item: NavBarItem): boolean => {
	if (window.location.pathname === item.path) {
		return true;
	} else if (item.path && item.path !== '/' && window.location.pathname.startsWith(item.path)) {
		return true;
	}

	// TODO: Solución temporal hasta que se corrijan las rutas
	if (item.path === '/quotations' && window.location.pathname.startsWith('/list')) {
		return true;
	}

	return false;
};

const navbarSection = (drawerCallback: Function, user: User | null, item: NavBarItem, listTitle: string = '') => {
	return (
		<ListStyled
			key={item.title}
			sx={{ paddingBottom: 0, paddingRight: 0, width: '100%' }}
			subheader={
				<ListSubheader
					disableSticky
					component="div"
					id="nested-list-subheader"
					className="pl-[1.25rem] font-bold w-full"
				>
					{listTitle}
				</ListSubheader>
			}
		>
			<ListItem key={item.title} disablePadding>
				<ListItemButton
					selected={isSelected(item)}
					sx={{ py: { xl: '0.5rem', lg: '0.3rem', md: '0.1rem' }, pl: 0, width: '100%' }}
					onClick={() => drawerCallback(item, user)}
				>
					<ListItemIcon className="min-w-[2rem]">{item.icon}</ListItemIcon>
					<ListItemText
						primary={item.title}
						primaryTypographyProps={{ variant: 'body2', color: '#72727E', fontSize: '0.875rem !important' }}
					/>
					{item?.rightIcon}
				</ListItemButton>
			</ListItem>
		</ListStyled>
	);
};

const DrawerContainer = (props: Props) => {
	const isTablet = useIsTablet();
	const baseTheme = useTheme();
	const matchesXs = useMediaQuery(baseTheme.breakpoints.down('lg'));
	const matchesXl = useMediaQuery(baseTheme.breakpoints.up('xl'));
	const user = useRecoilValue(userState);
	const hideDrawerMenu = useRecoilValue(hideDraweMenuState);
	const setDisableRootElementScroll = useSetRecoilState(disableRootElementScrollState);
	const { remoteConfig } = props;
	const { logout } = useAuth0();
	const navigate = useNavigate();
	const [changeDialog, setChangeDialog] = React.useState(false);
	const [helpDialog, setHelpDialog] = React.useState(false);
	const [drawerVisible, setDrawerVisible] = React.useState<boolean>(false);
	const onToggle = () => setDrawerVisible(!drawerVisible);
	const desktopDrawerWidth = matchesXl ? 270 : 231;
	const drawerWidth = desktopDrawerWidth;
	const intl = useIntl();
	const [collabAlertState, setCollabAlertState] = React.useState(false);
	const [unreadNotif, setUnreadNotif] = React.useState(false);
	const [collaboratorsCounter, setCollaboratorsCounter] = React.useState<Number>();

	React.useEffect(() => {
		const fetchCollaboratorsCounter = debounce(() => {
			user &&
				client.getCollaboratorsCount({ user: user }).then((data) => {
					setCollaboratorsCounter(data[0].total_collaborators);
				});
		});
		fetchCollaboratorsCounter();
	}, [user]);

	React.useEffect(() => {
		setDisableRootElementScroll(drawerVisible);
	}, [drawerVisible]);

	const home: NavBarItem = {
		title: intl.formatMessage({ id: 'navbar.lateral_menu.home' }),
		icon: <HomeIcon />,
		path: '/home',
	};

	const newQuotation: NavBarItem = {
		title: intl.formatMessage({ id: 'navbar.lateral_menu.quotations' }),
		icon: <InsertDriveFileIcon />,
		path: '/quotations',
	};

	const referencePrices: NavBarItem = {
		title: intl.formatMessage({ id: 'navbar.lateral_menu.reference_prices' }),
		icon: <MonetizationOnRoundedIcon />,
		path: '/reference-prices',
	};

	const paymentCalendar: NavBarItem = {
		title: intl.formatMessage({ id: 'navbar.lateral_menu.payment_calendar' }),
		icon: <CalendarMonthIcon />,
		path: '/payment-calendar',
	};

	const configuration: NavBarItem = {
		title: intl.formatMessage({ id: 'common.account_configuration' }),
		icon: <SettingsIcon />,
		path: '/profile',
	};

	const projects: NavBarItem = {
		title: intl.formatMessage({ id: 'navbar.lateral_menu.projects' }),
		icon: <AssignmentIcon />,
		path: '/projects',
	};

	const tokens: NavBarItem = {
		title: intl.formatMessage({ id: `navbar.${user?.type_external}.lateral_menu.my_safekeepings` }),
		icon: <WalletIcon />,
		path: '/my-tokens',
	};

	const audiences: NavBarItem = {
		title: intl.formatMessage({ id: `navbar.seller.lateral_menu.customer_segments` }),
		icon: <GroupsIcon />,
		path: '/audiences',
	};

	const externalHelp = (title: string) => {
		return { title: intl.formatMessage({ id: `${title}` }), icon: <QuestionAnswerIcon />, action: handleOpenHelp };
	};
	const help: NavBarItem = {
		title: intl.formatMessage({ id: `navbar.${user?.type_external}.lateral_menu.help` }),
		icon: <HelpIcon />,
		path: '/help',
	};

	const optionsByRole = {
		[Role.CONSTRUCTOR]: [home, newQuotation, tokens, referencePrices, projects],
		[Role.SELLER]: [newQuotation],
	};

	// optionsByRole[Role.CONSTRUCTOR].push(paymentCalendar);

	if (remoteConfig?.ACOPIO?.allowed_users?.includes(user?.email)) {
		optionsByRole[Role.SELLER].push(tokens);
	}

	if (
		remoteConfig?.AUDIENCIAS?.allowed_users?.includes(user?.email) ||
		remoteConfig?.AUDIENCIAS?.allowed_users?.includes('*')
	) {
		optionsByRole[Role.SELLER].push(audiences);
	}

	optionsByRole[Role.CONSTRUCTOR].push(configuration);
	optionsByRole[Role.CONSTRUCTOR].push(externalHelp('navbar.constructor.lateral_menu.help'));
	optionsByRole[Role.SELLER].push(externalHelp('navbar.lateral_menu.seller.questions'));

	const options = user ? optionsByRole[user.type_external].filter((item) => !item.hide) : [];

	const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

	const drawerCallback = (item: NavBarItem, user: User) => {
		if (item.path) {
			matchesXs && drawerVisible && onToggle();
			navigate(item.path);
		} else {
			item?.action && item.action(user, () => setHelpDialog(true));
		}
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const handleChangeConstructor = (user: User) => {
		handleCloseUserMenu();
		if (user?.admin) {
			setChangeDialog(true);
		} else {
			// alerty de error
		}
	};

	const handleChangeSeller = (user: User) => {
		handleCloseUserMenu();
		if (user?.admin) {
			setChangeDialog(true);
		} else {
			// alerty de error
		}
	};

	const handleCloseSession = () => {
		handleCloseUserMenu();
		Object.keys(localStorage).map((key) => {
			localStorage.removeItem(key);
		});
		localStorage.clear();
		logout({ logoutParams: { returnTo: window.location.origin } });
	};

	const changeConstructor: UserMenu = {
		action: () => handleChangeConstructor,
		id: 'header.menu.switch_constructor_button',
		admin: true,
	};
	const changeSeller: UserMenu = {
		action: () => handleChangeSeller,
		id: 'header.menu.switch_seller_button',
		admin: true,
	};
	const signOut: UserMenu = { action: handleCloseSession, id: 'header.menu.logout_button', admin: false };

	const menuByRole = {
		[Role.CONSTRUCTOR]: (isAdmin: boolean) =>
			[changeConstructor, signOut].filter((option) => (isAdmin ? true : !option.admin)),
		[Role.SELLER]: (isAdmin: boolean) => [changeSeller, signOut].filter((option) => (isAdmin ? true : !option.admin)),
	};

	const menu = user ? menuByRole[user.type_external || Role.CONSTRUCTOR](user.admin) : [];

	const getMenuOption = (user: User): UserMenuOptions[] => {
		let menuOptions: UserMenuOptions[] = [];

		if (user.type_external === Role.CONSTRUCTOR) {
			user.role === CollaboratorRole.ADMIN &&
				menuOptions.push({
					description: intl.formatMessage({ id: 'navbar.lateral_menu.collaborators' }),
					onclick: () => {
						navigate('/collaborators');
					},
					notificationState: collabAlertState,
				});

			menuOptions.push({
				isDivider: true,
				description: '',
			});

			user.email.includes('@nuqlea') &&
				menuOptions.push({
					description: intl.formatMessage({ id: 'header.menu.switch_constructor_button' }),
					onclick: () => handleChangeConstructor(user),
				});
		}

		if (user.type_external === 'seller') {
			user.email.includes('@nuqlea') &&
				menuOptions.push({
					description: intl.formatMessage({ id: 'header.menu.switch_seller_button' }),
					onclick: () => handleChangeSeller(user),
				});
		}

		menuOptions.push({
			description: intl.formatMessage({ id: 'header.menu.logout_button' }),
			onclick: handleCloseSession,
		});

		return menuOptions;
	};

	return (
		<Box>
			<AppBar
				position="absolute"
				elevation={matchesXs ? 1 : 0}
				sx={{
					zIndex: (theme) => theme.zIndex.drawer - 1,
					height: { xs: 55, xl: 64 },
					justifyContent: 'center',
				}}
			>
				<Toolbar
					disableGutters
					sx={{
						bgcolor: '#f8f9fa',
						justifyContent: matchesXs ? 'space-between' : 'end',
					}}
				>
					{matchesXs && (
						<IconButton
							onClick={onToggle}
							size="large"
							edge="start"
							aria-label="open drawer"
							sx={{ padding: '0 0 0 30px' }}
						>
							<MenuIcon />
						</IconButton>
					)}

					{matchesXs && (
						<Button
							onClick={() => {
								navigate(user?.type_external === Role.CONSTRUCTOR ? '/home' : '/quotations');
							}}
							className="h-[3.75rem] p-0 flex-col mt-1 ml-2"
						>
							<img
								alt=""
								style={{ marginLeft: '20px', alignSelf: 'self-start' }}
								src={logo}
								height={matchesXs ? 25 : 30}
							/>
						</Button>
					)}

					<Box className="h-full pr-1 lg:pr-6 w-fit flex items-center gap-1">
						<Box className="mr-2">{!matchesXs && <NotificationsBell user={user}></NotificationsBell>}</Box>
						{user && (
							<AvatarUserMenu
								showNotificationBadge={unreadNotif}
								mobileNotifCallback={() => navigate('/notifications')}
								isMobile={matchesXs}
								user={user}
								menuOptions={getMenuOption(user)}
								organizationInfo={
									user?.type_external === 'constructor' ? `${collaboratorsCounter} colaboradores` : undefined
								}
							></AvatarUserMenu>
						)}

						<Menu
							id="menu-appbar"
							anchorEl={anchorElUser}
							container={document.body}
							open={Boolean(anchorElUser)}
							onClose={handleCloseUserMenu}
						>
							{menu.map((item) => (
								<MenuItem key={item.id} onClick={() => item.action(user)}>
									{intl.formatMessage({ id: item.id })}
								</MenuItem>
							))}
						</Menu>
					</Box>
				</Toolbar>
			</AppBar>
			{!hideDrawerMenu && (
				<Drawer
					variant={isTablet ? "permanent" : "temporary"}
					sx={{
						width: '17rem',
						flexShrink: 0,
						[`& .MuiDrawer-paper`]: {
							width: '17rem',
							boxSizing: 'border-box',
							borderRight: 'transparent',
							mt: matchesXs ? '60px' : 0,
							pb: matchesXs ? '60px' : 0,
						},
					}}
					ModalProps={{
						keepMounted: true,
						BackdropProps: {
							invisible: true,
						},
					}}
					open={matchesXs ? drawerVisible : true}
					onClose={onToggle}
				>
					<Box className="flex flex-col h-full pb-2 mx-5">
						{!matchesXs && (
							<Button
								onClick={() => {
									navigate(user?.type_external === Role.CONSTRUCTOR ? '/home' : '/quotations');
								}}
								className="h-[3.75rem] p-0 flex-col mt-2"
							>
								<img
									alt=""
									style={{ alignSelf: 'self-start' }}
									src={logo}
									height={20}
								/>
							</Button>
						)}

						<ListItem className="block py-7 px-0 mx-0">
							{user?.type_external === Role.CONSTRUCTOR && (
								<Box>
									<ListItemButton
										onClick={() => {
											if (drawerVisible) onToggle();
											
											if(window.location.hostname === 'studio.nuqlea.com') {
												(window as any).dataLayer?.push({
													"event": "click_sidebar_menu_pedir_cotizacion",
													"value": {
														"user": {
															"user_id": user?.id,
															"user_email": user?.email,
															"type_external": user?.type_external,
															"id_external": user?.id_external,
															"name_external": user?.name_external,
															"role": user?.role,
															"environment": user?.enviroment
														}
													}
												})
											}

											navigate('/new-quotation');
										}}
										sx={{
											backgroundColor: 'primary.main',
											borderRadius: '0.37rem',
											color: 'white',
											fontWeight: 500,
											justifyContent: 'center',
											margin: 'auto',
											'&:hover': {
												bgcolor: '#3445F2',
											},
											width: '100%',
											fontSize: '0.875rem',
										}}
									>
										{intl.formatMessage({ id: 'navbar.lateral_menu.new_quotation' })}
									</ListItemButton>

									{/* TODO: Standarize colors on theme */}
								</Box>
							)}
						</ListItem>
						<Box className='flex flex-col justify-between h-full'>
							<Box>
								{options.slice(0, getOptionsSlice(user, matchesXs))?.map((option, index) => (
									<React.Fragment key={`${index}${option.path}`}>
										<ListItem className='p-0'>
											{navbarSection(drawerCallback, user, option, '')}
										</ListItem>
									</React.Fragment>
								))}
							</Box>
							<Box className='pt-6'>
								{user?.type_external === Role.CONSTRUCTOR && (
									<Paper
										id="sidebar_menu_solicitar_demo"
										elevation={3}
										className='flex flex-col bg-[#F0F0F2] mx-1 mt-4 mb-6 rounded-lg px-4 pt-3 py-2 gap-5 cursor-pointer'
										onClick={() => {
											if(window.location.hostname === 'studio.nuqlea.com') {
												(window as any).dataLayer?.push({
													"event": "click_sidebar_menu_solicitar_demo",
													"value": {
														"user": {
															"user_id": user?.id,
															"user_email": user?.email,
															"type_external": user?.type_external,
															"id_external": user?.id_external,
															"name_external": user?.name_external,
															"role": user?.role,
															"environment": user?.enviroment
														}
													}
												})
											}
											window.open('https://calendly.com/contacto-nuqlea/30min', '_blank');
										}}
										sx={{
											':hover': {
												cursor: 'pointer',
												transform: 'translateY(-6px)',
											},
											transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
										}}	
									>
										<Typography className='text-sm font-semibold'>
											Te ayudamos a sacarle el máximo a Nuqlea
										</Typography>
										
										<img
											alt=""
											className='my-2 w-4/5 hidden 2xl:block'
											style={{ objectFit: 'contain', alignSelf: 'center' }}
											src={newDemo}
										/>

										<Button
											className='bg-[#EAF1FE] rounded text-xs hover:bg-[#EAF1FE] mt-2 lg:mt-0'
											startIcon={<CalendarMonthIcon sx={{ width: '15px' }} />}
										>
											Solicitar demo
										</Button>
									</Paper>
								)}
								{!matchesXs && <Box>
									{options.slice(getOptionsSlice(user, matchesXs))?.map((option, index) => (
										<React.Fragment key={`${index}${option.path}`}>
											{index === 0 && !matchesXs && user?.type_external === Role.CONSTRUCTOR && (
												<Divider />
											)}
											{index === 0 && !matchesXs && user?.type_external === Role.SELLER && (
												<Divider />
											)}
											<ListItem
												sx={{
													padding: '0rem 0 0 0',
												}}
											>
												{navbarSection(drawerCallback, user, option, '')}
											</ListItem>
										</React.Fragment>
									))}
								</Box> }
							</Box>
						</Box>

					</Box>
				</Drawer>
			)}
			<ChangeDialog open={changeDialog} user={user} onClose={() => setChangeDialog(false)}></ChangeDialog>
			{user?.type_external === Role.SELLER ? (
				<HelpDialog open={helpDialog} onClose={() => setHelpDialog(false)}></HelpDialog>
			) : (
				<HelpDialogArchbee open={helpDialog} onClose={() => setHelpDialog(false)}></HelpDialogArchbee>
			)}
		</Box>
	);
};

export default DrawerContainer;
