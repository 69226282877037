import { ExpandMore } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import {
	Box,
	Grid,
	Button,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Modal,
	Fade,
	IconButton,
	Tooltip,
} from '@mui/material';
import { useIntl } from 'react-intl';
import GenericConfirmationModal from '../../modals/generic-confirmation-modal';
import TaxProfileDetails from './tax-profile-details';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import { BusinessName, User } from '../../../types';
import businessNameClient from '../../../clients/businessNameClient';
import NuqleaAlert from '../../nuqlea-alert';

interface taxProfileTabProps {
	orgTaxProfiles?: BusinessName[];
	editTaxProfile?: (edition: boolean, taxProfile?: BusinessName) => void;
	updateBusinessNamesList: () => void;
	token: string;
	setEmptyBusinessNames?: (isEmpty: boolean) => void;
	user: User;
}

const TaxProfileTab: React.FC<taxProfileTabProps> = ({
	orgTaxProfiles,
	editTaxProfile,
	token,
	setEmptyBusinessNames,
	user,
	updateBusinessNamesList,
}) => {
	const intl = useIntl();

	const [taxProfiles, setTaxProfiles] = useState(orgTaxProfiles);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showNewPreferredModal, setShowNewPreferredModal] = useState(false);
	const [taxProfileToDelete, setTaxProfileToDelete] = useState<any>();
	const [newPreferredTaxProfile, setNewPreferredTaxProfile] = useState<BusinessName>();
	const [modalLoading, setModalLoading] = useState(false);

	const switchEditMode = (edition: boolean, taxProfileToEdit?: any) => {
		editTaxProfile && editTaxProfile(edition, taxProfileToEdit);
	};

	const onDeleteModal = async () => {
		taxProfileToDelete && (await handleDelete(taxProfileToDelete));
		setShowDeleteModal(false);
	};

	const onSetNewPreferredTaxProfile = async () => {
		newPreferredTaxProfile && (await handleNewPreferredTaxProfile(newPreferredTaxProfile));
		setShowNewPreferredModal(false);
	};
	const onCancelModal = () => {
		setShowDeleteModal(false);
		setShowNewPreferredModal(false);
		setTaxProfileToDelete(undefined);
	};

	const handleDelete = async (taxProfileToDelete: BusinessName) => {
		setModalLoading(true);
		const wasPreferred = taxProfileToDelete.default_business_name ? true : false;
		await businessNameClient.deleteBusinessName({ id: String(taxProfileToDelete.id), user: user });

		if (wasPreferred) {
			let mainProfile = taxProfiles?.find((item) => item.main === true);
			if (mainProfile) {
				mainProfile.default_business_name = true;
				mainProfile.files = null;
				await businessNameClient.updateBusinessName({ data: mainProfile, user: user });
			}
		}
		setModalLoading(false);
		updateBusinessNamesList();
	};

	const handleNewPreferredTaxProfile = async (newPreferred: BusinessName) => {
		setModalLoading(true);
		const previousPreferred = taxProfiles?.find((item) => item.default_business_name == true);

		if (previousPreferred) {
			previousPreferred.default_business_name = false;
			previousPreferred.files = null;
			await businessNameClient.updateBusinessName({ data: previousPreferred, user: user });
		}

		newPreferred.default_business_name = true;
		newPreferred.files = null;
		await businessNameClient.updateBusinessName({ data: newPreferred, user: user });
		setModalLoading(false);
		updateBusinessNamesList();
	};

	const isValidInvoiceProfile = (profile: BusinessName) => {
		return profile.validInvoiceProfile;
	};

	useEffect(() => {
		setTaxProfiles(orgTaxProfiles);
	}, [orgTaxProfiles]);

	return (
		<Box>
			<Grid container spacing={2}>
				<Grid item xs={12} className="flex justify-end">
					<Button onClick={() => switchEditMode(true, undefined)} className="text-base" size="small">
						{intl.formatMessage({ id: 'common.add_CUIT' })}
					</Button>
				</Grid>

				{taxProfiles?.map((profile: BusinessName) => {
					const isValidInvoiceData = isValidInvoiceProfile(profile);

					return profile ? (
						<Grid item xs={12} key={profile.id}>
							<Accordion defaultExpanded={taxProfiles.length === 1}>
								<AccordionSummary expandIcon={<ExpandMore className="h-[30px] w-[30px]" />}>
									<Grid container direction="row" className="w-full justify-start items-center" spacing={0}>
										<Grid item xs={1}>
											<Tooltip
												title={
													profile.default_business_name &&
													intl.formatMessage({ id: 'settings.company.tax_profile_tab.preferred_cuit' })
												}
											>
												<IconButton
													sx={{
														color: profile.default_business_name ? 'primary.main' : 'grey.400',
														width: '30px',
														height: '30px',
													}}
													onClick={(event) => {
														setShowNewPreferredModal(!profile.default_business_name);
														setNewPreferredTaxProfile(profile);
														event.stopPropagation();
													}}
												>
													<LabelImportantIcon className="w-[1.5rem] h-[1.5rem]" />
												</IconButton>
											</Tooltip>
										</Grid>
										<Grid item xs={5} lg={4} justifyContent={'flex-start'}>
											<Typography variant="h6" className="pl-6 font-bold">
												{profile.business_social}
											</Typography>
											<Typography variant="h6" className="pl-6 font-bold">
												{profile.cuit}
											</Typography>
										</Grid>
										{!isValidInvoiceData && (
											<Grid item xs={5}>
												<NuqleaAlert>
													{intl.formatMessage({
														id: 'settings.company.tax_profile_tab.incomplete_tax_profile_warning',
													})}
												</NuqleaAlert>
											</Grid>
										)}
									</Grid>
								</AccordionSummary>
								<AccordionDetails>
									<TaxProfileDetails
										handleDelete={(taxProfile: BusinessName) => {
											setTaxProfileToDelete(taxProfile);
											setShowDeleteModal(true);
										}}
										switchEditMode={(edition) => switchEditMode(edition, profile)}
										taxProfile={profile}
										user={user}
									/>
								</AccordionDetails>
							</Accordion>
						</Grid>
					) : (
						<></>
					);
				})}
			</Grid>
			<Modal open={showDeleteModal} onKeyDown={(event) => event.key === 'Escape' && setShowDeleteModal(false)}>
				<Fade in={showDeleteModal}>
					<Box>
						<GenericConfirmationModal
							isLoading={modalLoading}
							title={intl.formatMessage({ id: 'settings.company.tax_profile_tab.remove_tax_profile' })}
							message={intl.formatMessage({ id: 'settings.company.tax_profile_tab.remove_tax_profile_confirmation' })}
							primaryButtonText={intl.formatMessage({
								id: 'common.delete',
							})}
							primaryButtonCallback={onDeleteModal}
							secondaryButtonText={intl.formatMessage({
								id: 'common.cancel',
							})}
							secondaryButtonCallback={onCancelModal}
						></GenericConfirmationModal>
					</Box>
				</Fade>
			</Modal>
			<Modal
				open={showNewPreferredModal}
				onKeyDown={(event) => event.key === 'Escape' && setShowNewPreferredModal(false)}
			>
				<Fade in={showNewPreferredModal}>
					<Box>
						<GenericConfirmationModal
							isLoading={modalLoading}
							title={intl.formatMessage({ id: 'settings.company.tax_profile_tab.preferred_cuit' })}
							message={intl.formatMessage({ id: 'settings.company.tax_profile_tab.preferred_cuit_confirmation' })}
							primaryButtonText={intl.formatMessage({
								id: 'common.confirm',
							})}
							primaryButtonCallback={onSetNewPreferredTaxProfile}
							secondaryButtonText={intl.formatMessage({
								id: 'common.cancel',
							})}
							secondaryButtonCallback={onCancelModal}
						></GenericConfirmationModal>
					</Box>
				</Fade>
			</Modal>
		</Box>
	);
};

export default TaxProfileTab;
