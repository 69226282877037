import { Box, MenuItem, InputLabel, TextField, Button, Stack, LinearProgress } from '@mui/material';
import { useIntl } from 'react-intl';
import ImageUploader from '../../image-uploader';
import { useState } from 'react';
import SelectWithPlaceholder from '../../select-with-placeholder';
import SelectMultiWithPlaceholder from '../../select-multiple-with-placeholder';
import { construct } from './main-settings';
import client from '../../../clients/client';
import { fileToBase64 } from '../../../utils/data-utils';
import logoPlaceholder from '../../../assets/logoPlaceholder.png';
import { useMobile } from '../../../hooks/use-mobile';
import GenericTabFrame from '../../base-components/generic-tab-frame';

interface generalEditionTabProps {
	callback: (isEditionMode: boolean) => void;
	updateOrganizationData: () => void;
	constructorData: construct;
	showRequiredError: (show: boolean) => void;
	token: string;
}

const GeneralEditionTab: React.FC<generalEditionTabProps> = ({
	callback,
	updateOrganizationData,
	constructorData,
	showRequiredError,
	token,
}) => {
	const intl = useIntl();
	const isMobile = useMobile();

	const getProjectTypes = () => {
		let types = constructorData.project_type;
		if (types) {
			return types.includes(',') ? types.split(',') : [types];
		}
		return ['0'];
	};

	const [profileTypeSelected, setProfileTypeSelected] = useState(constructorData.user_type ?? '0');
	const [profileTypeError, setProfileTypeError] = useState(false);
	const [projectTypesSelected, setProjectTypesSelected] = useState<string[]>(getProjectTypes());
	const [projectTypeError, setProjectTypeError] = useState(false);
	const [principalSelected, setPrincipalSelected] = useState(constructorData.perfil_constructora ?? '0');
	const [principalError, setPrincipalError] = useState(false);
	const [address, setAddress] = useState(constructorData.domicilio_comercial ?? '');
	const [collabSelected, setCollabSelected] = useState(constructorData.employee_quantity ?? '0');
	const [isSaving, setIsSaving] = useState(false);

	const hadPreviousLogo = constructorData.image_url ? true : false;

	const profileTypes = [
		{ value: 'Constructora' },
		{ value: 'Desarrolladora' },
		{ value: 'Estudio de arquitectura' },
		{ value: 'Gerenciadora' },
		{ value: 'Otros' },
	];

	const projectTypes = [
		{ value: 'Vivienda unifamiliar' },
		{ value: 'Vivienda multifamiliar' },
		{ value: 'Otras obras de arquitectura' },
		{ value: 'Loteos' },
		{ value: 'Industrial' },
		{ value: 'Obras viajes' },
		{ value: 'Otras obras de infraestructura' },
		{ value: 'Otros' },
	];

	const principalPartyTypes = [{ value: 'Pública' }, { value: 'Privada' }, { value: 'Mixta' }];

	const collabsRangeTypes = [{ value: '1-9' }, { value: '10-49' }, { value: '50-99' }, { value: '>100' }];

	const [uploadedImage, setUploadedImage] = useState<File | null>(null);

	const handleImageSelect = (image: File) => {
		setUploadedImage(image);
	};

	const verifyRequiredFields = (): boolean => {
		let requiredCompleted = true;

		if (profileTypeSelected === '0') {
			setProfileTypeError(true);
			requiredCompleted = false;
		}
		if (projectTypesSelected.includes('0')) {
			setProjectTypeError(true);
			requiredCompleted = false;
		}
		if (principalSelected === '0') {
			setPrincipalError(true);
			requiredCompleted = false;
		}
		return requiredCompleted;
	};

	const handleSave = async () => {
		const logoData = uploadedImage && (await fileToBase64(uploadedImage));
		const data = {
			id: constructorData.id_constructora,
			constructorName: constructorData.name_constructora,
			profile: profileTypeSelected,
			projectType: projectTypesSelected.join(','),
			address: address,
			principalParty: principalSelected,
			collabQty: collabSelected,
			logoFile: logoData?.base64,
			logoFileName: logoData?.name,
			firstTimeLogo: !hadPreviousLogo,
		};
		if (verifyRequiredFields()) {
			showRequiredError(false);
			try {
				setIsSaving(true);
				await client.updateConstructorData({ data: data, token: token });
				updateOrganizationData();
				callback(false);
			} catch (error) {
				console.log(error);
			} finally {
				setIsSaving(false);
			}
		} else {
			showRequiredError(true);
		}
	};

	return (
		<GenericTabFrame
			title={intl.formatMessage({ id: 'settings.company.general_tab.no_info_title' })}
			subtitle={intl.formatMessage({ id: 'settings.company.general_tab.no_info_subtitle' })}
			buttons={[
				<Button disabled={isSaving} onClick={() => callback(false)} variant="outlined">
					{intl.formatMessage({ id: 'common.cancel' })}
				</Button>,
				<Button disabled={isSaving} onClick={handleSave} variant="contained" sx={{ ml: '10px' }}>
					{intl.formatMessage({ id: 'common.save' })}
				</Button>,
			]}
			variant="edition"
		>
			<Stack justifyContent={'space-between'} width={'100%'} minHeight={'34rem'}>
				<Box>
					<ImageUploader
						label={intl.formatMessage({ id: 'settings.company.general_tab.logo' })}
						description={intl.formatMessage({ id: 'settings.company.general_tab.logo_description' })}
						onImageSelect={handleImageSelect}
						currentImg={constructorData.image_url ?? logoPlaceholder}
					></ImageUploader>
				</Box>
				<Box>
					<SelectWithPlaceholder
						label={intl.formatMessage({ id: 'settings.company.general_tab.profile_input' })}
						placeholder={intl.formatMessage({ id: 'settings.company.select_placeholder' })}
						onChange={(event) => setProfileTypeSelected(event.target.value as string)}
						size="small"
						fullWidth
						value={profileTypeSelected}
						required
						error={profileTypeError}
					>
						{profileTypes.map((item) => {
							return (
								<MenuItem key={item.value} value={item.value} id={item.value}>
									{item.value}
								</MenuItem>
							);
						})}
					</SelectWithPlaceholder>
				</Box>
				<Box>
					<SelectMultiWithPlaceholder
						label={intl.formatMessage({ id: 'settings.company.general_tab.project_type_input' })}
						placeholder={intl.formatMessage({
							id: 'settings.company.general_tab.project_type_input_placeholder',
						})}
						onValueChangeCallback={setProjectTypesSelected}
						value={projectTypesSelected}
						fullWidth
						required
						error={projectTypeError}
					>
						{projectTypes.map((item) => {
							return (
								<MenuItem key={item.value} value={item.value} id={item.value}>
									{item.value}
								</MenuItem>
							);
						})}
					</SelectMultiWithPlaceholder>
				</Box>
				<Box>
					<SelectWithPlaceholder
						label={intl.formatMessage({ id: 'settings.company.general_tab.principal_party_input' })}
						placeholder={intl.formatMessage({ id: 'settings.company.select_placeholder' })}
						onChange={(event) => setPrincipalSelected(event.target.value as string)}
						size="small"
						fullWidth
						value={principalSelected}
						required
						error={principalError}
					>
						{principalPartyTypes.map((item) => {
							return (
								<MenuItem key={item.value} value={item.value} id={item.value}>
									{item.value}
								</MenuItem>
							);
						})}
					</SelectWithPlaceholder>
				</Box>
				<Box>
					<InputLabel>{intl.formatMessage({ id: 'settings.company.general_tab.address_input' })}</InputLabel>
					<TextField
						placeholder={intl.formatMessage({ id: 'settings.company.general_tab.address_input_placeholder' })}
						value={address}
						onChange={(event) => setAddress(event.target.value as string)}
						size="small"
						variant="outlined"
						sx={{ width: '100%' }}
					></TextField>
				</Box>
				<Box>
					<SelectWithPlaceholder
						label={intl.formatMessage({ id: 'settings.company.general_tab.collabs_input' })}
						placeholder={intl.formatMessage({ id: 'settings.company.general_tab.collabs_input_placeholder' })}
						onChange={(event) => setCollabSelected(event.target.value as string)}
						size="small"
						fullWidth
						value={collabSelected}
					>
						{collabsRangeTypes.map((item) => {
							return (
								<MenuItem key={item.value} value={item.value} id={item.value}>
									{item.value}
								</MenuItem>
							);
						})}
					</SelectWithPlaceholder>
				</Box>
			</Stack>
			{isSaving && <LinearProgress sx={{ mt: '2rem' }}></LinearProgress>}
		</GenericTabFrame>
	);
};

export default GeneralEditionTab;
