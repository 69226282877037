import { Box, Table, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { Delivery, Role } from '../../../../types';
import { useMemo } from 'react';
import userState from '../../../../atoms/user-state';
import { useRecoilValue } from 'recoil';
import { truncToDecimals } from '../../../../utils/data-utils';

interface Props {
	delivery: Delivery;
	redeemStep?: number;
	unity: string;
	modalType: 'new' | 'view' | 'edit';
	setError: (error: string) => void;
	redeemRequestProducts: any[];
	newDeliveryData: Delivery;
	setNewDeliveryData: (data: Delivery) => void;
}

const DeliveryProducts = (props: Props) => {
	const {
		delivery,
		unity,
		redeemStep,
		modalType,
		setError,
		redeemRequestProducts,
		newDeliveryData,
		setNewDeliveryData,
	} = props;
	const user = useRecoilValue(userState);

	const intl = useIntl();

	// can't edit if delivery if status is completed, except on creating a new delivery
	const isEditing = modalType === 'new' || (modalType === 'edit' && delivery.status === 1);
	
	const showProductEquivalence = (eq: any, quant: any) => {	
		return (
			<Box className="flex justify-center">
				<Typography sx={{ fontSize: '1.2em' }}>{intl.formatNumber(Number(eq * quant), { style: 'decimal' })}</Typography>
			</Box>
		);
	}

	const decimalsFromRedeemStep = useMemo(() => {
		if (!redeemStep || Math.floor(redeemStep) === redeemStep) {
			return 0;
		} else {
			return redeemStep.toString().split('.')[1].length || 0;
		}
	}, [redeemStep]);

	const handleOnProductChange = (e: any, product: any, redeemRequestProduct: any, originalProductValue: number) => {
		const value = e.target.value === '' ? '' : truncToDecimals(parseFloat(e.target.value), decimalsFromRedeemStep);
		
		setError('');

		if (value && value < 0) {
			return;
		}
		
		if (value && value > redeemRequestProduct.unassigned + originalProductValue) {
			setError(intl.formatMessage({ id: 'mytokens.redeem_view.deliveries.seller_delivery_modal.error.quantity_product' }));
		};
		const newProducts = newDeliveryData?.products?.map((p) => p.redeem_request_product_id === product.redeem_request_product_id ? { ...p, quantity: value } : p);

		setNewDeliveryData({ ...newDeliveryData, products: newProducts });
	}

	const redeemRequestProductsMap: Map<string, any> = useMemo(() => {
		const map = new Map();
		redeemRequestProducts.forEach((product) => {
			map.set(product.id, product);
		});
		return map;
	}, [redeemRequestProducts]);
	
	const totalAvailable: number = useMemo(() => {
		const totalUnassigned = redeemRequestProducts.reduce((acc, product) => acc + Number(product.unassigned), 0) ?? 0;
		const totalProducts = delivery?.products?.reduce((acc, product) => acc + Number(product.quantity), 0) ?? 0;
		return totalUnassigned + totalProducts;
	}, [redeemRequestProducts, delivery?.products]);

	return (
		<Table className="mb-6">
			<TableHead>
				<TableRow>
					<TableCell className='border-none py-0'>{intl.formatMessage({ id: 'mytokens.redeem-shippings.delivery-products.name' })}</TableCell>
					<TableCell className='border-none capitalize text-center py-0' >{`${unity}s` || 'Unidades'}</TableCell>
					{user.type_external !== Role.SELLER ? (
						<TableCell className='border-none capitalize text-center py-0'>{'Tokens'}</TableCell>
					) : (
						<TableCell className='border-none'></TableCell>
					)}
				</TableRow>
			</TableHead>
			{
				newDeliveryData?.products?.map(product => {
					const redeemRequestProduct = redeemRequestProductsMap.get(product.redeem_request_product_id);
					const originalProductValue = delivery?.products?.find((p) => p.redeem_request_product_id === product.redeem_request_product_id)?.quantity ?? 0;
					return (
						<TableRow key={product.redeem_request_product_id}>
							<TableCell className='border-none'>{redeemRequestProduct.product_name}</TableCell>
							<TableCell className='border-none flex items-center gap-2'>
								<TextField
									disabled={!isEditing}
									type="number"
									variant="outlined"
									size="small"
									value={product.quantity}
									onChange={(e) => handleOnProductChange(e, product, redeemRequestProduct, originalProductValue)}
									inputProps={{
										min: 0,
										step: redeemStep ?? 1,
										style: {
											textAlign: 'center',
											fontSize: '1.2em',
											maxHeight: 20,
											maxWidth: 140,
											paddingLeft: 1,
											paddingRight: 1,
										}
									}}
									sx={{
										'& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
											{
												'-webkit-appearance': 'none',
												margin: 0,
											},
										'& input[type=number]': {
											'-moz-appearance': 'textfield',
										},
									}}
									error={product.quantity > redeemRequestProduct.unassigned + originalProductValue}
								/>
							</TableCell>
							{user.type_external === Role.CONSTRUCTOR ? (
								<TableCell className="border-none items-end">
									{showProductEquivalence(redeemRequestProduct.equivalence, product.quantity)}
								</TableCell>
							) : (
								<TableCell className='border-none'>
									{isEditing ? (
										<Box className='border-none flex items-center gap-2'>
											de <Box><Typography sx={{ fontSize: '1.2em' }}>
												{intl.formatNumber(redeemRequestProduct.unassigned + originalProductValue, { style: 'decimal' })}
											</Typography></Box>
										</Box>
									) : null}
								</TableCell>
							)}
						</TableRow>
					)
				})
			}
			<TableRow sx={{background: '#EAF1FE'}}>
				<TableCell sx={{ borderRadius: '10px 0 0 10px', border: 'none', background: '#EAF1FE', color: 'black', fontWeight: 800, fontSize: '1.2em', minHeight: '3.7em' }}>Total</TableCell>
				<TableCell sx={{ border: 'none', background: '#EAF1FE', display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '3.7em' }}>
					<Typography sx={{ fontWeight: 800, fontSize: '1.2em', textAlign: 'center' }}>
						{intl.formatNumber(newDeliveryData?.products?.reduce((acc, product) => acc + Number(product.quantity), 0) ?? 0 , { style: 'decimal' })}
					</Typography>
				</TableCell>
				{user.type_external === Role.CONSTRUCTOR ? (
					<TableCell sx={{ borderRadius: '0 10px 10px 0', border: 'none', background: '#EAF1FE', alignItems: 'center', justifyContent: 'end', gap: 7.4 }}>
						<Box><Typography sx={{ fontWeight: 800, fontSize: '1.2em', textAlign: 'center' }}>
							{intl.formatNumber(
								newDeliveryData?.products?.reduce((acc, product) => 
									acc + (Number(product.quantity) * Number(redeemRequestProducts.find(item => item.product_id === product.redeem_request_product_product_id).equivalence)), 0)
								?? 0
							, { style: 'decimal' })}
						</Typography></Box>
					</TableCell>
				) :  (
					<TableCell sx={{ borderRadius: '0 10px 10px 0', border: 'none', background: '#EAF1FE', color: 'black', fontWeight: 800, fontSize: '1.2em', minHeight: '3.7em', gap: 2 }}>
						{isEditing ? (
							<Box className='border-none flex items-center gap-2'>de <Typography sx={{ fontSize: '1.2em'}}>{intl.formatNumber(totalAvailable, { style: 'decimal' })}</Typography></Box>
						) : null}
					</TableCell>
				)}
			</TableRow>
		</Table>
	)
};

export default DeliveryProducts;
