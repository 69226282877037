import { Box, Typography } from '@mui/material';

interface Props {
	children: React.ReactNode;
	error?: boolean;
	title: string;
	titleExtraStyles?: React.CSSProperties;
}

const InputContainer = ({
	children,
	error,
	title,
	titleExtraStyles,
}: Props) => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'end',
				width: 'auto',
			}}
		>
			<Typography
				variant="body2"
				sx={{
					fontWeight: 900,
					color: '#4B4B4B',
					marginBottom: '4px', 
					...(error ? { color: '#FF3D00' } : {}),
					...titleExtraStyles,
					// ...(titleExtraStyles ? titleExtraStyles : {}) 
				}}
			>
				{title}
			</Typography>
			{children}
		</Box>
	);
};

export default InputContainer;
