import { Box, Grid, debounce, Typography, Stack, Card, CardContent, Link, Skeleton, Divider } from '@mui/material';
import {
	Document,
	FilesList,
	Response,
	TokenDetailStatus,
	Role,
	StockSafekeeping,
	AgreementConditionTypes,
	StackedData,
} from '../../../types';
import TableCardTreeView from '../../table-card-tree-view';
import { Upload } from '@mui/icons-material';
import { relatedFiles } from '../../details/columns';
import FileUploadDialog from '../../details/dialogs/file-upload-dialog';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import client from '../../../clients/client';
import { useIntl } from 'react-intl';
import Error from '../../error';
import { formatNumberLocale } from '../../../utils/data-utils';
import userState from '../../../atoms/user-state';
import { useRecoilValue } from 'recoil';
import ClientInfoComponent from '../../client-info';
import BasePageFrame from '../../base-components/base-page-frame';
import StackDataDisplay from '../../base-components/stack-data-display';
import MapsDialog from '../buy-tokens/maps-dialog';
import { ShippingZone } from '../buy-tokens/reducer';
import { Action } from '../../card';
import ClientBillingInfoDialog, { ClientData } from '../../client-billing-info-dialog';
import StatusCardV2, { Status } from '../../status-card-v2';



const TokenDetailViewSeller = () => {
	const { id } = useParams();
	const user = useRecoilValue(userState);	
	const intl = useIntl();
	const navigate = useNavigate();

	const allowedFileTypes = [
		{ id: 6, name: 'Factura' },		
		{ id: 13, name: 'Nota de crédito' },
		{ id: 15, name: 'Nota de débito' },
		{ id: 14, name: 'Recibo de pago' },
	];

	const [isLoading, setIsLoading] = useState(true);
	const [showError, setShowError] = useState(false);
	const [stockSafekeepingResponse, setStockSafekeepingResponse] = useState<Response<StockSafekeeping>>();
	const [sSks, setSSks] = useState<StockSafekeeping>();
	const [agreementConditions, setAgreementConditions] = useState<any>([]);
	const [fileUploadDialogOpen, setFileUploadDialogOpen] = useState(false);
	const [allDocuments, setAllDocuments] = useState<Document[]>([]);
	const [filesResponse, setFilesResponse] = useState<Response<FilesList>>();
	const [stockSafekeepingDetails, setStockSafekeepingDetails] = useState<StackedData[]>([]);
	const [counts, setCounts] = useState<Status[]>();
	const [mapsDialogOpen, setMapsDialogOpen] = useState(false);
	const [shippingZones, setShippingZones] = useState<ShippingZone[]>([]);
	const [shippingZone, setShippingZone] = useState<ShippingZone>();
	const [actionButtons, setActionButtons] = useState<Action[]>();
	const [showClientInfoDialog, setShowClientInfoDialog] = useState(false);
	const [clientData, setClientData] = useState<ClientData>({});
	const [clientFiles, setClientFiles] = useState<any[]>([]);
	const [showBreakdownCostsLink, setShowBreakdownCostsLink] = useState(true);

	const handleGoBack = () => {
		navigate(`/my-tokens`);
	};

	const handleGoToCostBreakdown = () => {
		navigate(`/my-tokens/${id}/cost-breakdown`);
	};

	const fetchStockSafekeeping = debounce(async (fetchDocs: Boolean) => {
		setStockSafekeepingResponse(undefined);

		const response = await client.getStockSafekeeping({ token: user.token, sSkId: id, role: Role.SELLER });

		if (response && response.sSks) {
			setStockSafekeepingResponse(response);
			getAgreementConditionsForSsk(response.sSks.id);
			fetchStockSafekeepingOrder(response.sSks.id);

			if (fetchDocs) {
				fetchListDocuments(response.sSks.order_id, id);
			}

			checkStatus(response.sSks);

			configActionButtons(response.sSks);
		}

		setIsLoading(false);
	}, 250);

	const getAgreementConditionsForSsk = async (sskid: string) => {
		try {
			const response = await client.getAgreementConditionForSsk({
				token: user.token,
				sSkId: sskid,
			});

			setAgreementConditions(response.conditions);

		} catch (error) {

		}
	};

	const fetchStockSafekeepingOrder = async (sskid: string) => {
		setShowBreakdownCostsLink(false);

		const result = await client.getStockSafekeepingOrder({
			token: user.token,
			sSkId: sskid,
		});

		if (result && result.monto_unit_token) {
			setShowBreakdownCostsLink(true);
		}
	};

	const getDelivery = (conditions: []) => {
		const delivery: any = conditions?.find((item: any) => item.condition_type_id === AgreementConditionTypes.SHIPPING || item.condition_type_id === AgreementConditionTypes.WITHDRAWAL);
		return delivery ?? { agreement_condition_type_label: '-', agreement_condition_zone_label: '-' };
	};

	const getFinancingCondition = (conditions: []) => {
		const financing: any = conditions.find((item: any) => item.condition_type_id && (item.condition_type_id === AgreementConditionTypes.FINANCING));
		return financing ? financing.name : 'Sin financiación';
	};

	const fetchListDocuments = debounce((orderId, sSkId) => {
		setFilesResponse(undefined);

		client
			.getTokenDocuments({ orderId, sSkId, user })
			.then((data) => {
				setFilesResponse({ data });
				const filteredDocs = data?.map((file: Document) => file);
				setAllDocuments(filteredDocs ?? []);
			})
			.catch(() => setFilesResponse({ error: true }));
	}, 250);

	useEffect(() => {
		try {

			fetchStockSafekeeping(true);

		} catch (error) {
			console.error(error);
			setShowError(true);
		};
	}, [id]);


	const checkStatus = (sSk: any) => {
		setCounts([
			{ label: TokenDetailStatus.REQUEST_RECEIVED, active: false, completed: true },
			{ 
				label: TokenDetailStatus.INVOICE_SENT, 
				active: sSk?.Estado_orden === 1, 
				completed: sSk?.Estado_orden > 1,
				tooltip: intl.formatMessage({ id: 'mytokens.seller.detail.invoice_awaiting' })
			},
			{ 
				label: TokenDetailStatus.PAYMENT_SENT, 
				active: sSk?.Estado_orden === 2, 
				completed: sSk?.Estado_orden > 2,
				tooltip: intl.formatMessage({ id: 'mytokens.seller.detail.payment_sent' })
			},
			{ label: TokenDetailStatus.ACCREDITED, active: sSk?.Estado_orden === 4, completed: sSk?.Estado_orden > 3 },
		]);
	};

	const configActionButtons = (sSk: any) => {
		let actionButtons: any = [];
		if (sSk.Estado_orden === 1) {
			actionButtons = [
				{
					displayName: intl.formatMessage({ id: 'list.seller.details.upload_file.invoice' }),
					icon: <Upload />,
					type: 'Button',
					onClick: () => setFileUploadDialogOpen(true),
				},
				{
					displayName: intl.formatMessage({ id: 'mytokens.acquisition_details.show_client_details_full_name' }),
					type: 'Button',
					variant: 'text',
					onClick: () => setShowClientInfoDialog(true),
				}
			]
		} else {
			actionButtons = [{
				displayName: intl.formatMessage({ id: 'list.seller.details.upload_file.action' }),
				icon: <Upload />,
				type: 'Button',
				onClick: () => setFileUploadDialogOpen(true),
			}]
		}

		setActionButtons(actionButtons);
	}

	const getCompositeDescription = (sSks?: any) => {
		return (
			<Stack direction={{ sm: 'column', lg: 'row' }} justifyContent={'flex-end'} spacing={{ sm: 0, lg: 1 }} divider={<Divider orientation="vertical" flexItem />}>
				<Box
					display={'flex'}
					justifyContent={'flex-end'}
					alignItems={'center'}
					minWidth={'100%'}
					overflow={'hidden'} >
					<Typography variant={'body2'} textAlign={'end'} whiteSpace={'nowrap'} overflow={'hidden'} textOverflow={'ellipsis'} maxWidth={'100%'}>
						{sSks?.business_social}
					</Typography>
				</Box>
				<Box display={'flex'} justifyContent={'flex-end'}>
					<ClientInfoComponent customMessage={intl.formatMessage({ id: 'mytokens.acquisition_details.show_client_details' })} businessName={sSks} showButton={false} />
				</Box>
			</Stack>
		)
	}

	useEffect(() => {
		if (stockSafekeepingResponse) {
			const { sSks } = stockSafekeepingResponse as any;
			const delivery = getDelivery(agreementConditions);
			const shippingZone = {
				zone_id: delivery?.agreement_condition_zone_id,
				zone_label: delivery?.agreement_condition_zone_label,
				agreement_condition_id: delivery?.id,
				zone_url: delivery?.agreement_condition_zone_url,
			};

			setSSks(sSks);
			setShippingZone(shippingZone);
			setShippingZones([shippingZone]);
			setStockSafekeepingDetails([
				{
					title: intl.formatMessage({ id: 'mytokens.acquisition_details.client' }),
					description: '-',
					objectDesc: getCompositeDescription(sSks),
				},
				{
					title: intl.formatMessage({ id: 'mytokens.acquisition_details.amount' }),
					description: `${formatNumberLocale(sSks?.quantity ?? 0)} tokens`,
				},
				{
					title: intl.formatMessage({ id: 'mytokens.acquisition_details.total_cost' }),
					description: intl.formatNumber(sSks?.Monto_final, {
						style: 'currency',
						currency: 'ARS',
						currencyDisplay: 'narrowSymbol',
					}),
				},
				{
					title: intl.formatMessage({ id: 'mytokens.seller.acquisition_details.financing' }),
					description: agreementConditions?.length ? getFinancingCondition(agreementConditions) : '-',
				},
				{
					title: delivery?.condition_type_id && delivery?.condition_type_id === AgreementConditionTypes.SHIPPING
						? intl.formatMessage({ id: 'mytokens.acquisition_details.shipping_zone' })
						: delivery?.agreement_condition_type_label,
					description: delivery?.agreement_condition_zone_label,
					objectDesc: delivery?.condition_type_id && delivery?.condition_type_id === AgreementConditionTypes.SHIPPING
						? <Box onClick={() => setMapsDialogOpen(true)} sx={{ cursor: 'pointer' }}>
							<Typography variant={'body2'} color={'blue'} textAlign={'end'}>
								{delivery?.agreement_condition_zone_label}
							</Typography>
						</Box>
						: <Link sx={{ textDecoration: 'none', '& :visited': { color: 'blue' } }} href={shippingZone?.zone_url ?? ''} target='_blank'>
							<Typography variant={'body2'} color={'blue'} textAlign={'end'}>
								{delivery?.agreement_condition_zone_label}
							</Typography>
						</Link>
				},
			]);
			setClientData({ name: sSks?.business_social, taxId: sSks?.cuit });
			setClientFiles(sSks?.files);
		}
	}, [stockSafekeepingResponse, agreementConditions]);

	const forceInsertNewFile = (newFile: Document) => {
		if (newFile.parent_documents_ids) {
			const allRelations = newFile.parent_documents_ids.split(',');
			let allNewDocs = allDocuments;

			allRelations.map((fileId: string) => {
				const foundIndex = allNewDocs.findIndex((el) => el.file_id === fileId);
				allNewDocs[foundIndex].children = [...allNewDocs[foundIndex].children, newFile];

				return fileId;
			});

			setAllDocuments(allNewDocs);
		} else {
			setAllDocuments([...allDocuments, newFile]);
		}
		
		if (newFile.file_type_id === 6 && sSks?.Estado_orden === 1) {
			setCounts([
				{ label: TokenDetailStatus.REQUEST_RECEIVED, active: false, completed: true },
				{ label: TokenDetailStatus.INVOICE_SENT, active: false, completed: true },
				{ label: TokenDetailStatus.PAID, active: true, completed: false },
				{ label: TokenDetailStatus.ACCREDITED, active: false, completed: false },
			]);
		}
	};

	return (
		<BasePageFrame
			title={intl.formatMessage({ id: 'mytokens.seller.detail.title' })}
			returnAction={handleGoBack}
			transparent
		>
			{showError ?
				<Error /> :
				<Grid container spacing={2}>
					<Grid item xs={12}>
						{isLoading ?
							<Skeleton variant="rectangular" height={200} /> :
							<StatusCardV2
								titleLocation="center"
								statuses={counts || []}
								elevation={0}
							/>}
					</Grid>
					<Grid item xs={12}>
						{isLoading ?
							<Skeleton variant="rectangular" height={300} /> :
							<Card elevation={0}>
								<CardContent sx={{ p: 4 }}>
									<Grid container spacing={2} justifyContent="space-between" >
										<Grid item xs={12} md={6}>
											<Box>
												<Typography variant={'h5'}>
													{intl.formatMessage({ id: 'mytokens.seller.acquisition_details.request_details' })}
												</Typography>
											</Box>
											<Box>
												<Stack direction="row" spacing={1} divider={<Divider orientation="vertical" flexItem />}>
													<Typography variant={'body2'}>
														{intl.formatDate(sSks?.created_at, { timeZone: 'UTC' })}
													</Typography>
													{showBreakdownCostsLink && <Typography variant={'body2'}>
														<Link onClick={() => handleGoToCostBreakdown()} sx={{ cursor: 'pointer' }} underline="none">
															{intl.formatMessage({ id: 'mytokens.seller.acquisition_details.show_cost_breakdown' })}
														</Link>
													</Typography>}
												</Stack>
											</Box>
										</Grid>
										<Grid item xs={12} md={6}>
											<StackDataDisplay data={stockSafekeepingDetails} descriptionTextAlign='right' ></StackDataDisplay>
										</Grid>
									</Grid>
								</CardContent>
							</Card>
						}
					</Grid>
					<Grid item xs={12}>
						{isLoading ?
							<Skeleton variant="rectangular" height={300} /> :
							<TableCardTreeView
								loading={!filesResponse}
								title={intl.formatMessage({ id: 'list.seller.details.related_files.title' })}
								layoutColumns={5}
								borderRadius={1}
								columns={relatedFiles}
								relatedColumns={relatedFiles}
								rows={allDocuments?.map((document) => ({ ...document, user, list_id: '2bfc5999' }))}
								actions={actionButtons}
								elevation={0}
							></TableCardTreeView>
						}
					</Grid>
				</Grid>
			}

			<FileUploadDialog
				tokenization={true}
				dataTokenization={sSks}
				open={fileUploadDialogOpen}
				selectedType={sSks?.Estado_orden === 1 ? 6 : undefined}
				types={allowedFileTypes.filter((fileType) => {
					if ([13, 14, 15].includes(fileType.id)) {
						return (
							allDocuments?.filter(
								(document) => document.label_file_type === 'Factura' || document.label_file_type === 'Proforma'
							).length > 0
						);
					}

					return true;
				})}
				documents={
					allDocuments?.filter(
						(document) => document.label_file_type === 'Factura' || document.label_file_type === 'Proforma'
					) || []
				}
				user={user}
				onClose={(event, reason, newFile) => {
					setFileUploadDialogOpen(false);
					if (newFile) forceInsertNewFile(newFile);

					if (!['cancelButtonClick', 'backdropClick'].includes(reason ?? '') && newFile)
						fetchListDocuments(sSks?.order_id, id);
				}}
			/>

			<MapsDialog
				deliveriesConditions={agreementConditions?.find((item: any) => item.condition_type_id === AgreementConditionTypes.SHIPPING || item.condition_type_id === AgreementConditionTypes.WITHDRAWAL)}
				open={mapsDialogOpen}
				onClose={() => setMapsDialogOpen(false)}
				zones={shippingZones}
				setZone={() => { }}
				initialZone={shippingZone}
				isReadOnly={true}
			/>

			<ClientBillingInfoDialog open={showClientInfoDialog} onClose={() => setShowClientInfoDialog(false)} clientData={clientData} files={clientFiles} />

		</BasePageFrame>
	);
};

export default TokenDetailViewSeller;
