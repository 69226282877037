import styled from '@emotion/styled';
import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useIntl } from 'react-intl';
import { useMobile } from '../../../hooks/use-mobile';
import { useEffect, useRef, useState } from 'react';

interface Props {
	type: string;
	activeTab: any;
	changeActiveTab: Function;
}

const TabsContainer = styled(Box)`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 1rem 0;
	margin: 1rem 0;
`;

const TabContainer = styled.div<{ active: boolean, isPlaceholderDiv?: boolean }>`
	display: flex;
	border-bottom: ${(props) => props.active ? '2px solid #406AFF' : '1px solid #C6C6C666'};
	color: ${(props) => props.active && 'blue'};
	font-weight: ${(props) => (props.active ? '600' : '400')};
	flex-grow: ${(props) => props.isPlaceholderDiv ? '1' : '0'};
	flex-wrap: wrap;
	justify-content: center;
	align-content: center;
	height: 100%;
	min-height: 71px;
	max-height: 71px;
`;

const TabDivider = styled.div<{ isLastDivider?: boolean }>`
	display: flex;
	border-bottom: 1px solid #C6C6C666;
	min-width: 2rem;
	height: 100%;
	flex-grow: ${(props) => props.isLastDivider ? '1' : '0'};
	min-height: 71px;
	max-height: 71px;
`;

const Tab = styled.p<{ isPlaceholderP?: boolean }>`
	text-align: center;
	font-size: 14px;
	opacity: ${(props) => props.isPlaceholderP ? '0' : '1'};
	cursor: ${(props) => props.isPlaceholderP ? 'inherit' : 'pointer'};
	user-select: ${(props) => props.isPlaceholderP ? 'none' : 'inherit'};
	width: ${(props) => props.isPlaceholderP ? '100%' : 'auto'};
`;

const tabNamesMapper = {
	0: 'purchase',
	1: 'exchange-process',
	2: 'active-tokens',
	3: 'ended',
} as any;

const Tabs = (props: Props) => {
	const { type, activeTab, changeActiveTab } = props;
	
	const intl = useIntl();
	const mobile = useMobile();

	const [pIsInTwoLines, setPIsInTwoLines] = useState(false);
    const paragraphRef = useRef<HTMLParagraphElement>(null);

    const checkIfTwoLines = () => {
        const node = paragraphRef.current;
        if (node) {
            const lineHeight = parseFloat(getComputedStyle(node).lineHeight);
            const actualHeight = node.clientHeight;
            setPIsInTwoLines(actualHeight > lineHeight && actualHeight <= 2 * lineHeight);
        }
    };

    useEffect(() => {
        checkIfTwoLines();
        window.addEventListener('resize', checkIfTwoLines);

        return () => {
            window.removeEventListener('resize', checkIfTwoLines);
        };
    }, []);


	const handleChange = (event: SelectChangeEvent) => {
		changeActiveTab({
			index: event.target.value,
			name: tabNamesMapper[event.target.value],
		});
	};

	return mobile ? (
		<Select
			disableUnderline
			labelId="demo-simple-select-label"
			id="demo-simple-select"
			value={activeTab}
			onChange={handleChange}
			sx={{ margin: '1rem 0' }}
		>
			<MenuItem value={0}>{intl.formatMessage({ id: `mytokens.${type}.dashboard.first_tab` })}</MenuItem>
			<MenuItem value={1}>{intl.formatMessage({ id: `mytokens.${type}.dashboard.second_tab` })}</MenuItem>
			<MenuItem value={2}>{intl.formatMessage({ id: `mytokens.${type}.dashboard.third_tab` })}</MenuItem>
			<MenuItem value={3}>{intl.formatMessage({ id: `mytokens.${type}.dashboard.fourth_tab` })}</MenuItem>
		</Select>
	) : (
		<TabsContainer>
			<TabContainer
				active={activeTab === 0}
			>
				<Tab
					ref={paragraphRef}
					onClick={() => changeActiveTab({ index: 0, name: tabNamesMapper[0] })}
				>
					{intl.formatMessage({ id: `mytokens.${type}.dashboard.first_tab` })}
				</Tab>
			</TabContainer>
			<TabDivider>
				<Tab isPlaceholderP>
					{'|'}
					{ pIsInTwoLines ? <><br />|</> : '' }
				</Tab>
			</TabDivider>
			<TabContainer
				active={activeTab === 1}
			>
				<Tab
					onClick={() => changeActiveTab({ index: 1, name: tabNamesMapper[1] })}
				>
					{intl.formatMessage({ id: `mytokens.${type}.dashboard.second_tab` })}
				</Tab>
			</TabContainer>
			<TabDivider>
				<Tab isPlaceholderP>
					{'|'}
					{ pIsInTwoLines ? <><br />|</> : '' }
				</Tab>
			</TabDivider>
			<TabContainer
				active={activeTab === 2}
			>
				<Tab
					onClick={() => changeActiveTab({ index: 2, name: tabNamesMapper[2] })}
				>
					{intl.formatMessage({ id: `mytokens.${type}.dashboard.third_tab` })}
				</Tab>
			</TabContainer>
			<TabDivider>
				<Tab isPlaceholderP>
					{'|'}
					{ pIsInTwoLines ? <><br />|</> : '' }
				</Tab>
			</TabDivider>
			<TabContainer
				active={activeTab === 3}
			>
				<Tab
					onClick={() => changeActiveTab({ index: 3, name: tabNamesMapper[3] })}
				>
					{intl.formatMessage({ id: `mytokens.${type}.dashboard.fourth_tab` })}
				</Tab>
			</TabContainer>
			<TabDivider isLastDivider>
				<Tab isPlaceholderP>
					{'|'}
					{ pIsInTwoLines ? <><br />|</> : '' }
				</Tab>
			</TabDivider>
		</TabsContainer>
	);
};

export default Tabs;
