export const Patterns = {
  // Eliminar cualquier carácter que no sea números, espacios, comas, puntos o guiones
  numbersAndSymb: /[^0-9\s,.-]+/g,

  // Eliminar cualquier cosa que no sean letras y espacios
  letters: /[^a-zA-Z\s]+/g,

  // Eliminar cualquier cosa que no sea caracteres válidos de un email
  email: /[^a-zA-Z0-9._%+-@]+/g,

  // Eliminar cualquier cosa que no sean números
  onlyNumbers: /[^0-9]+/g,

  // Eliminar comillas dobles, simples o invertidas
  noQuotes: /['"`]+/g
};

export const sanitizeString = (
	value: string,
	pattern: 'numbersAndSymb' | 'letters' | 'email' | 'onlyNumbers' |'noQuotes'
): string => {
	return value === '' ? '' : value.replace(Patterns[pattern], '');
};





