import { Card, CardContent, Grid, Box, Typography, CardProps } from "@mui/material";
import { Column, PaginatableTable } from "../../table-paginatable";
import { useIntl } from "react-intl";
import { useMemo } from "react";
import { formatNumberWithCurrency } from "../../../utils/data-utils";
import { AgreementProduct } from "../../../types";

interface Props extends CardProps {
    stockSafekeepingOrder: any,
    agreement: any;
    agreementProducts: AgreementProduct[];
    bonusCondition: any;
    shippingCondition: any;
}

const ConvertionTableCard = ({ stockSafekeepingOrder, agreement, agreementProducts, bonusCondition, shippingCondition, ...rest }: Props) => {
    const intl = useIntl();

    const conversionTableColumns = useMemo(() => {
        if (!agreement) return [];

        let columns: Column[] = [
            {
                id: 'product',
                label: intl.formatMessage({ id: 'mytokens.seller.cost_breakdown.conversion_table.product' }),
                align: 'left',
                width: '10%',
            },
            {
                id: 'equivalence',
                label: intl.formatMessage({ id: 'mytokens.seller.cost_breakdown.conversion_table.equivalence' }),
                align: 'center',
                width: '10%',
            },
            {
                id: 'units',
                label: agreement?.label_units_in_presentation,
                align: 'center',
                width: '10%',
            },
            {
                id: 'price_per_unit',
                label: intl.formatMessage({ id: 'mytokens.seller.cost_breakdown.conversion_table.price_per_unit' }),
                align: 'center',
                width: '10%',
            },
            {
                id: 'price_per_presentation',
                label: intl.formatMessage({ id: 'mytokens.seller.cost_breakdown.conversion_table.price_per_presentation' }, { unit: agreement?.unity }),
                align: 'center',
                width: '10%',
            },
        ];

        if (bonusCondition) {
            columns.push(
                {
                    id: 'price_per_unit_with_discount',
                    label: intl.formatMessage({ id: 'mytokens.seller.cost_breakdown.conversion_table.price_per_unit_with_discount' }),
                    align: 'center',
                    width: '10%',
                },
                {
                    id: 'price_per_presentation_with_discount',
                    label: intl.formatMessage({ id: 'mytokens.seller.cost_breakdown.conversion_table.price_per_presentation_with_discount' }, { unit: agreement?.unity }),
                    align: 'center',
                    width: '10%',
                },
            );
        }

        if (shippingCondition) {
            columns.push(
                {
                    id: 'price_per_unit_with_discount_and_freight',
                    label: intl.formatMessage({ id: 'mytokens.seller.cost_breakdown.conversion_table.price_per_unit_with_discount_and_freight' }),
                    align: 'center',
                    width: '10%',
                },
            );
        }

        return columns;
    }, [agreement, bonusCondition, shippingCondition, intl]);

    
    const conversionTableRows = useMemo(() => {
        if (!agreement || agreementProducts.length === 0) return [];

        return agreementProducts.map((product) => {
            let formattedProduct: any = {
                product: product.name,
                equivalence: product.equivalence,
                units: product.units_in_presentation,
            };

            const unitAmount = (stockSafekeepingOrder.monto_unit_token * product.equivalence) / product.units_in_presentation;
            const presentationAmount = stockSafekeepingOrder.monto_unit_token * product.equivalence;

            formattedProduct['price_per_unit'] = formatNumberWithCurrency(unitAmount, 2);
            formattedProduct['price_per_presentation'] = formatNumberWithCurrency(presentationAmount, 2);

            let bonusUnitAmount = 0;
            if (bonusCondition) {
                bonusUnitAmount = unitAmount * (1 - bonusCondition.value / 100);
                formattedProduct['price_per_unit_with_discount'] = formatNumberWithCurrency(bonusUnitAmount, 2);

                const bonusPresentationAmount = presentationAmount * (1 - bonusCondition.value / 100);
                formattedProduct['price_per_presentation_with_discount'] = formatNumberWithCurrency(bonusPresentationAmount, 2);
            }

            if (shippingCondition) {
                const amount = bonusUnitAmount || unitAmount;
                const shippingUnitAmount = amount * (1 + shippingCondition.value / 100);
                formattedProduct['price_per_unit_with_discount_and_freight'] = formatNumberWithCurrency(shippingUnitAmount, 2);
            }

            return formattedProduct;
        });
    }, [agreement, agreementProducts, bonusCondition, shippingCondition, stockSafekeepingOrder]);

    return (
        <Card {...rest}>
            <CardContent sx={{ p: 4 }}>
                <Grid container spacing={2} justifyContent="space-between" >
                    <Grid item xs={12}>
                        <Box>
                            <Typography color={'text.primary'} variant='h5'>
                                {intl.formatMessage({ id: 'mytokens.seller.cost_breakdown.conversion_table.title' })}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} overflow={'auto'}>
                        <Box minWidth={'900px'} >
                            <PaginatableTable
                                columns={conversionTableColumns || []}
                                rows={conversionTableRows || []}
                                orderBy="product"
                                isEmptyDataSource={conversionTableRows.length === 0}
                                emptyDataSourceMessage={intl.formatMessage({ id: 'mytokens.seller.cost_breakdown.conversion_table.empty' })}
                                noResultsMessage={intl.formatMessage({ id: 'mytokens.seller.cost_breakdown.conversion_table.no_results' })}
                                overflowHidden={false}
                                disablePagination={false}
                            ></PaginatableTable>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default ConvertionTableCard;