import { Paper, Grid, Box, Typography, Button } from '@mui/material';
import { CtaType, HomeItem } from '../domain/HomeItem';
import EastIcon from '@mui/icons-material/East';
import { useNavigate } from 'react-router-dom';
import { useIsTablet } from '../../../hooks/use-isTablet';

const Banner = (props: HomeItem) => {
	const isTablet = useIsTablet();
	const navigate = useNavigate();

	const onClickCta = () => {
		const user = props.user;
		const { type, link, gtm_variable } = props.cta;

		if (gtm_variable && globalThis.location.hostname === 'studio.nuqlea.com') {
			(globalThis as any).dataLayer?.push({
				event: gtm_variable,
				value: {
					user: {
						user_id: user?.id,
						user_email: user?.email,
						type_external: user?.type_external,
						id_external: user?.id_external,
						name_external: user?.name_external,
						role: user?.role,
						environment: user?.enviroment,
					},
				},
			});
		}

		if (type === CtaType.navigation) {
			navigate(link);
		}
		if (type === CtaType.redirection) {
			navigate(link, { replace: true });
		}
	};

	return (
		<Grid item xs={12}>
			<Paper
				elevation={3}
				onClick={onClickCta}
				className="bg-white px-[2rem] md:px-[2.375rem] py-[1rem] xl:py-[1.43rem] 2xl:py-[2.375rem]"
				sx={{
					':hover': {
						cursor: 'pointer',
						transform: 'translateY(-6px)',
					},
					transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
					borderRadius: 2.8,
					backgroundImage: `url(${props.backgroundImg})`,
					backgroundSize: 'cover',
				}}
			>
				<Box className="flex h-full flex-col md:flex-row justify-start md:justify-between z-10">
					<Box className="flex flex-col justify-between xl:flex-[3] flex-[1] pt-1">
						<Box className="flex flex-col xl:w-[75%]">
							<Typography className="text-[#343434] font-semibold opacity-70 pb-6">{props.title}</Typography>
							<Typography variant="h3" className="pb-7">
								{props.description}
							</Typography>
						</Box>
						<Box>
							<Button
								endIcon={<EastIcon />}
								className="px-1 -ml-1 flex justify-start text-left hover:bg-transparent normal-case"
								size="large"
								onClick={onClickCta}
							>
								{props.cta.label}
							</Button>
						</Box>
					</Box>
					{isTablet && (
						<Box className="flex box-border justify-end xl:flex-[2] flex-[1] pr-6">
							<img className="object-contain w-[55%]" src={props.img} alt="" />
						</Box>
					)}
				</Box>
			</Paper>
		</Grid>
	);
};

export default Banner;
