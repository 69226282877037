import {
	Box,
	Button,
	Card,
	Paper,
	Grid,
	Dialog,
	Divider,
	Typography,
	styled as styledMaterial,
	useMediaQuery,
	useTheme,
	debounce,
} from '@mui/material';
import emptyStateLogo from '../../../assets/wallet-empty-state.svg';
import { useIntl } from 'react-intl';
import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import GenerateTokensButton from './GenerateTokensButton';
import MyTokensTable from './MyTokensTable';
import TokensQuantity from './tokens-quantity';
import { Role } from '../../../types';
import client from '../../../clients/client';
import { UpdateTokensContext } from '../../../contexts/updateTokensContext';
import HistoryTokensConfirmDialog from './HistoryTokensConfirmDialog';
import GenerateTokensDialog from './dialogs/GenerateTokensDialog';
import HistoryTokensDialog from './dialogs/HistoryTokensDialog';
import TermsAndConditionsDialog from './dialogs/TermAndConditionsDialog';
import { useQuery } from '../../../hooks/use-query';
import userState from '../../../atoms/user-state';
import { useRecoilValue } from 'recoil';
import BasePageFrame from '../../base-components/base-page-frame';

interface SellerAgreementContent {
	id: string | undefined;
}

interface ActiveAgreement {
	pricingId: string;
	pricing: number;
	validity: string;
	maxCapacity: number;
	minPurchase: number;
}

export const RoundedCard = styled(Card)`
	border-radius: 30px;
	height: 100%;
	position: relative;
	display: flex;
	flex-flow: column;
`;

const EmptyStateImage = styled.img`
	width: 300px;
`;

export const StyledMyTokensDashboardDialog = styledMaterial(Dialog)<{ width?: string }>(({ theme, width }) => ({
	'& .MuiPaper-root': {
		padding: theme.spacing(1),
		borderRadius: '10px',
		width: width || '80%',
		maxWidth: 'none',
	},
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
		gap: '16px',
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(2),
		marginRight: '10px',
	},
	'& .MuiTimelineItem-root': {
		minHeight: '0px',
		height: 'auto',
	},
	'& .MuiTypography-root': {
		paddingTop: '0',
	},
	'& .MuiTimelineConnector-root': {
		height: '30px',
		minHeight: 'none',
		backgroundColor: 'black',
	},
	'& .MuiTimelineDot-root': {
		width: '25px',
		height: '25px',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'black',
		fontSize: '13px',
		lineHeight: 1,
		marginTop: 0,
		marginBottom: 0,
	},
}));

const TokensSeller = () => {
	const user = useRecoilValue(userState);
	let query = useQuery();
	const [refresh, setRefresh] = useState<boolean>(false);
	const [termsAndConditionsDialog, setTermsAndConditionsDialog] = useState(false);
	const [clocksLoading, setClocksLoading] = useState(false);
	const [historyLoading, setHistoryLoading] = useState(false);
	const [generateTokenDialog, setGenerateTokenDialog] = useState(false);
	const [historyTokensDialog, sethistoryTokensDialog] = useState(false);
	const [historyTokensConfirmDialog, setHistoryTokensConfirmDialog] = useState<{
		type: 'pause' | 'reset';
		show: boolean;
	}>({
		type: 'pause',
		show: false,
	});
	const [tokensHistoryData, setTokensHistoryData] = useState<any[]>([]);
	const [isStateEmpty, setIsStateEmpty] = useState(true);
	const [selectedAgreement, setSelectedAgreement] = useState<SellerAgreementContent>({
		id: query.get('agreement') || undefined,
	});
	const [activeAgreementData, setActiveAgreementData] = useState<ActiveAgreement | undefined>(undefined);
	const intl = useIntl();
	const theme = useTheme();

	const lessThanTablet = useMediaQuery(theme.breakpoints.down('md'));

	const findDate = () => {
		const activeCollection = tokensHistoryData.find((item) => !!item.statusAction);
		return activeCollection?.createdAt;
	};

	const fetchToggleCollection = debounce((agreementId) => {
		setHistoryLoading(true);
		client
			.toggleCollection({ token: user.token, agreementId })
			.then((data) => {
				fetchTokensHistory(agreementId);
				setRefresh(true);
			})
			.catch((err) => {
				console.log(err);
			});
	});

	const fetchTokensHistory = debounce((agreementId) => {
		client
			.getTokensHistory({ token: user.token, agreementId })
			.then((data) => {
				const modifiedArray = data.data.map((item: any) => {
					if (item.status === 'active' || item.status === 'paused') {
						return { ...item, statusAction: item.status === 'active' ? 'Pausar' : 'Reanudar' };
					} else {
						return item;
					}
				});

				setTokensHistoryData(modifiedArray);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => setHistoryLoading(false));
	});

	const fetchAgreements = debounce(() => {
		client
			.getSellerAgreements({ token: user.token })
			.then((data) => {
				if (data.data.length === 0) {
					setIsStateEmpty(true);
				} else {
					setIsStateEmpty(false);
					setSelectedAgreement(data.data[0]);
					client
						.getAgreementsInfo({ token: user.token, agreementId: data.data[0].id })
						.then((data) => {
							if (data.data.length !== 0) {
								setActiveAgreementData(data.data[0]);
							} else {
								setActiveAgreementData(undefined);
							}
						})
						.catch((err) => {
							console.log(err);
						});
				}
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => setRefresh(true));
	}, 250);

	useEffect(() => {
		fetchAgreements();
	}, []);

	useEffect(() => {
		!!refresh && !!selectedAgreement.id && fetchTokensHistory(selectedAgreement.id);
	}, [refresh]);

	return (
		<UpdateTokensContext.Provider value={{ refresh, setRefresh }}>
			<BasePageFrame
				title={intl.formatMessage({ id: 'mytokens.seller.dashboard.title' })}
				subtitle={intl.formatMessage({ id: 'mytokens.seller.dashboard.subtitle' })}
				endAdornment={
					<Button
						variant="outlined"
						onClick={() => setTermsAndConditionsDialog(true)}
					>
						{intl.formatMessage({ id: 'mytokens.seller.dashboard.conditions_button' })}
					</Button>
				}
			>
				<TermsAndConditionsDialog
					open={termsAndConditionsDialog}
					onClose={() => setTermsAndConditionsDialog(false)}
					agreementId={selectedAgreement.id}
				/>
				{!!activeAgreementData && (
					<GenerateTokensDialog
						open={generateTokenDialog}
						onClose={() => setGenerateTokenDialog(false)}
						agreementData={activeAgreementData}
						token={user.token}
						agreementId={selectedAgreement.id}
					/>
				)}
				<HistoryTokensDialog
					loading={historyLoading}
					open={historyTokensDialog}
					data={tokensHistoryData}
					onClose={() => sethistoryTokensDialog(false)}
					actionButton={(row) => {
						setHistoryTokensConfirmDialog({ type: row.status === 'active' ? 'pause' : 'reset', show: true });
					}}
				/>
				<HistoryTokensConfirmDialog
					type={historyTokensConfirmDialog.type}
					open={historyTokensConfirmDialog.show}
					date={findDate()}
					onClose={() => setHistoryTokensConfirmDialog((prev) => ({ ...prev, show: false }))}
					actionButton={() => {
						setHistoryTokensConfirmDialog((prev) => ({ ...prev, show: false }));
						fetchToggleCollection(selectedAgreement.id);
					}}
				/>

				<Grid sx={{ ml: { md: '5px', xs: 0 } }} container spacing={{ md: 2, xs: 0 }}>
					<Grid item xs={12}>
						<Paper
							elevation={0}
							sx={{
								width: '100%',
								borderRadius: 2,
								height: '100%',
							}}
						>
							<Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: lessThanTablet ? 'column' : 'row' }}>
								<Box
									sx={{
										display: 'flex',
										flex: { md: 1, lg: 2 },
									}}
								>
									<Typography variant="h4" sx={{ flex: 1, marginBottom: '8px', fontSize: '24px' }}>
										{intl.formatMessage({ id: 'mytokens.seller.dashboard.transactions.title' })}
									</Typography>
								</Box>
								<Divider
									orientation={lessThanTablet ? 'horizontal' : 'vertical'}
									sx={{ height: 'auto', margin: lessThanTablet ? '1rem 0rem' : '0 1rem', borderColor: '#C6C6C666' }}
								/>
								<Box
									sx={{
										display: 'flex',
										flex: { md: 2, lg: 1 },
										gap: 1,
										justifyContent: lessThanTablet ? 'center' : 'space-evenly',
										flexWrap: lessThanTablet ? 'wrap' : 'nowrap',
										alignItems: 'center',
										marginTop: lessThanTablet ? '1rem' : 'auto',
									}}
								>
									<Button
										sx={{
											height: '2.5rem',
											width: '9.3rem',
										}}
										variant="contained"
										disabled={!activeAgreementData}
										onClick={() => setGenerateTokenDialog(true)}
									>
										{intl.formatMessage({ id: 'mytokens.seller.dashboard.generate_tokens_button' })}
									</Button>
									<Button
										variant="outlined"
										sx={{
											height: '2.5rem',
											width: '9.3rem',
										}}
										onClick={() => sethistoryTokensDialog(true)}
									>
										{intl.formatMessage({ id: 'mytokens.seller.dashboard.see_history' })}
									</Button>
								</Box>
							</Box>
							<Divider orientation="horizontal" sx={{ margin: '1rem 0', borderColor: '#C6C6C666' }} />
							<TokensQuantity
								type={Role.SELLER}
								token={user.token}
								agreementId={selectedAgreement.id}
								loading={clocksLoading}
								stopLoading={(state: boolean) => setClocksLoading(state)}
							/>
							{isStateEmpty ? (
								<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
									<EmptyStateImage src={emptyStateLogo} alt="empty-state-logo" />
									<Typography
										variant="h5"
										sx={{
											marginBottom: '3rem',
											marginTop: '0.5rem',
											fontSize: '24px',
											color: '#707070',
											width: '35%',
											textAlign: 'center',
										}}
									>
										{intl.formatMessage({ id: 'mytokens.seller.dashboard.transactions.empty_state' })}
									</Typography>
									<GenerateTokensButton disabled={!activeAgreementData} onClick={() => setGenerateTokenDialog(true)} />
								</Box>
							) : (
								<MyTokensTable type={Role.SELLER} user={user} agreementId={selectedAgreement.id} />
							)}
						</Paper>
					</Grid>
				</Grid>
			</BasePageFrame>
		</UpdateTokensContext.Provider>
	);
};

export default TokensSeller;
